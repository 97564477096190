@charset "UTF-8";
/*
** ==== Basic colors ====
*/
/*
** ==== XPO Brand Color Palette ====
*/
/*
** ==== Main Color Palette ====
*/
/*
** ==== DEPRECATED COLORS ====
*/
/*
** WARNING: THIS VARIABLES WILL BE REMOVED SOON
*/
/*
** ==== COLORS APPLIED ====
*/
/*
** ==== Typography ====
*/
/*
** ==== SNACKBAR DEPRECATED VARIABLES ====
*/
/*
** WARNING: THIS VARIABLES WILL BE REMOVED SOON
*/
.flex--stretch {
  flex: 1;
}

@font-face {
  font-family: "Roboto Wide Black";
  font-style: normal;
  font-weight: 900;
  src: url("https://xpodotcom.azureedge.net/xpo/files/roboto-wide--black.woff2") format("woff2"), url("https://xpodotcom.azureedge.net/xpo/files/roboto-wide--black.woff") format("woff");
}
/*! Base styles, made with normalize.css v7.0.0 */
*,
*::before,
*::after {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

:root {
  font-size: 14px;
}

html,
body {
  height: 100%;
  margin: 0;
}

html {
  line-height: 24px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  background-color: #f3f3f3;
  color: #4a4a4a;
  font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 24px;
  position: relative;
}

article,
aside,
footer,
header,
nav,
section {
  display: block;
}

h1,
h2,
h3 {
  margin: 8px 0;
}

h1 {
  font-size: 1.428rem;
  font-weight: 500;
  letter-spacing: 0.38px;
  line-height: 1.286;
}

h2 {
  font-size: 1.285rem;
  font-weight: 500;
  letter-spacing: 0.33px;
  line-height: 1.143;
}

h3 {
  font-size: 1.143rem;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 1.143;
}

p {
  margin: 0;
}

figcaption,
figure,
main {
  display: block;
}

figure {
  margin: 1em 40px;
}

hr {
  border: 0;
  border-top: 1px solid #d8d8d8;
  display: block;
  height: 1px;
  margin: 16px 0;
  padding: 0;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  background-color: transparent;
  color: #0868ac;
  text-decoration: none;
  -webkit-text-decoration-skip: objects;
}
a:hover {
  color: #0d94f4;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

dt {
  font-weight: 500;
}

dd {
  margin: 0 0 8px;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

code,
kbd,
samp {
  background-color: #d8d8d8;
  font-family: monospace, monospace;
  font-size: 0.9em;
  padding: 0.2em 0.3em;
}

dfn {
  font-style: italic;
}

mark {
  background-color: #ff0;
  color: #000;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

audio,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

img {
  border-style: none;
  max-width: 100%;
}

svg:not(:root) {
  overflow: hidden;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  display: inline-block;
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type=checkbox],
[type=radio] {
  box-sizing: border-box;
  padding: 0;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details,
menu {
  display: block;
}

summary {
  display: list-item;
}

canvas {
  display: inline-block;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

[mat-button].mat-button,
[mat-raised-button].mat-raised-button,
[mat-icon-button].mat-icon-button {
  border-width: 2px;
  height: 40px;
  line-height: inherit;
  padding: 0 20px;
  transition: color 200ms ease, background-color 200ms ease, border-color 200ms ease;
}

xpo-button-group.xpo-ButtonGroup > [mat-button].mat-button {
  margin-right: 16px;
}

[mat-button].mat-button {
  padding: 0;
}

[mat-icon-button].mat-icon-button {
  padding: 0;
}

[mat-raised-button].mat-raised-button {
  border-style: solid;
  border-width: 2px;
  box-shadow: none !important;
  color: #ffffff;
}
[mat-raised-button].mat-raised-button, [mat-raised-button].mat-raised-button.mat-primary {
  background-color: #1976d2;
  border-color: #1976d2;
}
[mat-raised-button].mat-raised-button:hover, [mat-raised-button].mat-raised-button.mat-primary:hover {
  background-color: #2196f3;
  border-color: #2196f3;
}
[mat-raised-button].mat-raised-button:focus, [mat-raised-button].mat-raised-button:active, [mat-raised-button].mat-raised-button.mat-primary:focus, [mat-raised-button].mat-raised-button.mat-primary:active {
  background-color: #0d47a1;
  border-color: #0d47a1;
}
[mat-raised-button].mat-raised-button.mat-warn {
  background-color: #d50000;
  border-color: #d50000;
}
[mat-raised-button].mat-raised-button.mat-warn:hover {
  background-color: #ff2222;
  border-color: #ff2222;
}
[mat-raised-button].mat-raised-button.mat-warn:focus, [mat-raised-button].mat-raised-button.mat-warn:active {
  background-color: #550000;
  border-color: #550000;
}
[mat-raised-button].mat-raised-button[disabled], [mat-raised-button].mat-raised-button[disabled]:active, [mat-raised-button].mat-raised-button[disabled]:hover, [mat-raised-button].mat-raised-button[disabled]:focus {
  background-color: #d8d8d8;
  border-color: #d8d8d8;
  color: #ffffff;
  cursor: not-allowed;
}

.mat-button,
.mat-icon-button {
  background-color: transparent;
}
.mat-button.mat-primary,
.mat-icon-button.mat-primary {
  color: #0868ac;
}
.mat-button.mat-primary:hover, .mat-button.mat-primary:focus,
.mat-icon-button.mat-primary:hover,
.mat-icon-button.mat-primary:focus {
  color: #0d94f4;
}
.mat-button.mat-primary:active,
.mat-icon-button.mat-primary:active {
  color: #053c63;
}
.mat-button, .mat-button.mat-accent,
.mat-icon-button,
.mat-icon-button.mat-accent {
  color: #4a4a4a;
}
.mat-button:hover, .mat-button:focus, .mat-button.mat-accent:hover, .mat-button.mat-accent:focus,
.mat-icon-button:hover,
.mat-icon-button:focus,
.mat-icon-button.mat-accent:hover,
.mat-icon-button.mat-accent:focus {
  color: #9b9b9b;
}
.mat-button:active, .mat-button.mat-accent:active,
.mat-icon-button:active,
.mat-icon-button.mat-accent:active {
  color: #4a4a4a;
}
.mat-button.mat-warn,
.mat-icon-button.mat-warn {
  color: #d50000;
}
.mat-button.mat-warn:hover, .mat-button.mat-warn:focus,
.mat-icon-button.mat-warn:hover,
.mat-icon-button.mat-warn:focus {
  color: #ff2222;
}
.mat-button.mat-warn:active,
.mat-icon-button.mat-warn:active {
  color: #550000;
}

[mat-raised-button].mat-raised-button {
  align-items: center;
  display: inline-flex;
}

[mat-button].mat-button[disabled], [mat-button].mat-button[disabled]:active, [mat-button].mat-button[disabled]:hover, [mat-button].mat-button[disabled]:focus,
[mat-icon-button].mat-icon-button[disabled],
[mat-icon-button].mat-icon-button[disabled]:active,
[mat-icon-button].mat-icon-button[disabled]:hover,
[mat-icon-button].mat-icon-button[disabled]:focus {
  background-color: transparent;
  border-color: #bdbdbd;
  color: #bdbdbd;
  cursor: not-allowed;
}

[mat-raised-button].mat-raised-button {
  border-radius: 2px;
}

[mat-button].mat-button.xpo-SmallButton,
[mat-raised-button].mat-raised-button.xpo-SmallButton,
[mat-icon-button].mat-icon-button.xpo-SmallButton {
  border-width: 1px;
  font-size: 1rem;
  height: 30px;
  line-height: normal;
  min-width: auto;
}
[mat-button].mat-button.xpo-SmallButton .mat-icon,
[mat-raised-button].mat-raised-button.xpo-SmallButton .mat-icon,
[mat-icon-button].mat-icon-button.xpo-SmallButton .mat-icon {
  font-size: 20px;
}
[mat-button].mat-button.xpo-SmallButton .mat-button-wrapper,
[mat-raised-button].mat-raised-button.xpo-SmallButton .mat-button-wrapper,
[mat-icon-button].mat-icon-button.xpo-SmallButton .mat-button-wrapper {
  line-height: 28px;
}

[mat-button].mat-button.xpo-LargeButton,
[mat-raised-button].mat-raised-button.xpo-LargeButton {
  font-size: 1.143rem;
  height: 50px;
  line-height: normal;
}
[mat-button].mat-button.xpo-LargeButton .mat-button-toggle-button,
[mat-raised-button].mat-raised-button.xpo-LargeButton .mat-button-toggle-button {
  font-size: 1.285rem;
  height: 100%;
}

/*
  1. These are carried over from Material but UX does not
      have any design for them yet. That's why they are not
      in the sample... they're a bit of a hidden API.
*/
.xpo-Link--accent {
  color: #4a4a4a;
}
.xpo-Link--accent:hover, .xpo-Link--accent:focus, .xpo-Link--accent:active {
  text-decoration: underline;
}
.xpo-Link--accent:hover, .xpo-Link--accent:focus {
  color: #4a4a4a;
  outline: 0;
}
.xpo-Link--accent:active {
  color: #171717;
}

.mat-chip-list .mat-chip-list-wrapper {
  gap: 6px;
}

.mat-chip.mat-standard-chip.xpo-Tag {
  background-color: #fafafa;
  border: 1px solid #d8d8d8;
  border-radius: 2px;
  color: #212121;
  display: flex;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.429rem;
  height: 24px;
  margin: 4px 0;
  min-height: 24px;
  padding: 2px 8px;
}
.mat-chip.mat-standard-chip.xpo-Tag > .mat-icon {
  font-size: 1.143rem;
  height: 16px;
  margin-left: 12px;
  margin-right: 0;
  width: 16px;
}
.mat-chip.mat-standard-chip.xpo-Tag .xpo-Icon {
  height: 16px;
  margin-left: 12px;
  width: 16px;
}
.mat-chip.mat-standard-chip.xpo-Tag .xpo-Icon .mat-icon {
  height: 16px;
  margin-right: 0;
  width: 16px;
}
.mat-chip.mat-standard-chip.xpo-Tag .mat-chip-remove.mat-chip-trailing-icon {
  margin-left: 12px;
  opacity: 1;
}
.mat-chip.mat-standard-chip.xpo-Tag .mat-chip-remove.mat-chip-trailing-icon:hover {
  opacity: 1;
}
.mat-chip.mat-standard-chip.xpo-Tag:hover {
  background-color: #ffffff;
}

/*
    1. Thanks to sub-pixels and how they render this caused some nasty
        blur. Ew! What one can do is start to add sub-pixels in along
        with the "em" (or rem, or points, or 🧀; it doesn't really
        matter). This is sub-pixel pushing so I just used my eye on
        a low-rez monitor and got it as close as I could. Because
        `calc` was uses this will be handled on the DOM instead of
        during the SCSS compilation which should give the rendering
        engine the best chance to make the correct decision.
    2. This is to support the text weight changing from the label
        changing weights as we want to animate this change instead
        of a snapping change.
    3. This large bit of classes is to identify if a prefix is attached
        to the input so that we can move the label better.
    4. Material has this set to 133.333% because they scale the label.
        We're changing that scale so we need to change the size of it.
*/
/*
    1. Threw a `div` to start this off to get the score to 0011 in order
        to beat Materials 0010. Mwahahaha.
    2. This disables the caret on anything but the first menu when there
        are nested menus. This is slightly fragile but sadly Material
        doesn't give us much of a choice here. We know the first panel
        is the second child of the overlay `div` so we get the second
        with `nth-of-type(2)` which gets the second `div`...then add
        `:not()` and you now get "everything that isn't the second div.
    3. This is because the first and last menu items have space added
        around them. The carets need to respect that and because the
        area round the buttons needs to accept the hover state this
        these need to be manually moved. All other carets are just
        moved 50% wholesale.
    4. Thanks to the reduced padding we need to move the submenu trigger
        icon over just a bit.
*/
/*
* The following styles style tabs that have been deprecated or are not in the design system 
* and also style the tab snippets in the Core documentation.
**/
.mat-primary .mat-tab-header .mat-tab-label-container .mat-ink-bar,
.mat-primary .mat-tab-header .mat-tab-label-container .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #0d94f4;
}
.mat-primary .mat-tab-label {
  color: #000000;
  height: 40px;
  line-height: 40px;
  margin-left: 0;
  min-width: 0;
  opacity: 1;
  padding: 4px 8px;
}
.mat-primary .mat-tab-label:first-child {
  margin-left: 0;
}
.mat-primary .mat-tab-label:hover, .mat-primary .mat-tab-label.mat-tab-label-active {
  color: #000000;
}
.mat-primary .mat-tab-label.mat-tab-disabled {
  color: #d8d8d8;
}
.mat-primary .mat-tab-label .mat-tab-link:hover, .mat-primary .mat-tab-label .mat-tab-link:focus {
  text-decoration: none;
}
.mat-primary .mat-tab-label .mat-tab-labels .mat-tab-label-content {
  padding-bottom: 0;
}
.mat-primary .mat-tab-label .mat-tab-labels .mat-tab-label-content .mat-chip.xpo-Chip--small {
  font-size: 12px;
  line-height: 16px;
  min-height: 16px;
}
.mat-primary .mat-tab-label .mat-tab-labels .mat-tab-label-content .mat-chip.xpo-Chip--small .mat-icon {
  height: 12px;
  width: 12px;
}
.mat-primary .mat-tab-label .mat-tab-labels .mat-tab-label-content > .mat-icon {
  align-items: center;
  display: flex;
  font-size: 1.143rem;
  width: auto;
}

.xpo-AlternateTabs.xpo-TabsWideNav .mat-tab-header-pagination,
.xpo-PillTabGroup.xpo-TabsWideNav .mat-tab-header-pagination,
.xpo-VerticalTabs.xpo-TabsWideNav .mat-tab-header-pagination,
.xpo-PrimaryTabs.xpo-TabsWideNav .mat-tab-header-pagination {
  min-width: 40px;
  width: 40px;
  padding: 8px;
}
.xpo-AlternateTabs .mat-tab-header,
.xpo-PillTabGroup .mat-tab-header,
.xpo-VerticalTabs .mat-tab-header,
.xpo-PrimaryTabs .mat-tab-header {
  border-bottom: none;
}
.xpo-AlternateTabs .mat-tab-header-pagination,
.xpo-PillTabGroup .mat-tab-header-pagination,
.xpo-VerticalTabs .mat-tab-header-pagination,
.xpo-PrimaryTabs .mat-tab-header-pagination {
  background-color: #f6f6f6;
  border: 1px solid #d8d8d8;
  box-shadow: none;
  min-width: 24px;
  height: 40px;
  opacity: 0.8;
  padding: 8px 0;
  width: 24px;
}
.xpo-AlternateTabs .mat-tab-header-pagination:hover:not(.mat-tab-header-pagination-disabled),
.xpo-PillTabGroup .mat-tab-header-pagination:hover:not(.mat-tab-header-pagination-disabled),
.xpo-VerticalTabs .mat-tab-header-pagination:hover:not(.mat-tab-header-pagination-disabled),
.xpo-PrimaryTabs .mat-tab-header-pagination:hover:not(.mat-tab-header-pagination-disabled) {
  background-color: #ffffff;
}
.xpo-AlternateTabs .mat-tab-header-pagination-disabled,
.xpo-PillTabGroup .mat-tab-header-pagination-disabled,
.xpo-VerticalTabs .mat-tab-header-pagination-disabled,
.xpo-PrimaryTabs .mat-tab-header-pagination-disabled {
  background: #f6f6f6;
}
.xpo-AlternateTabs .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.xpo-PillTabGroup .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.xpo-VerticalTabs .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.xpo-PrimaryTabs .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: #bdbdbd;
}
.xpo-AlternateTabs .mat-tab-header-pagination-chevron,
.xpo-PillTabGroup .mat-tab-header-pagination-chevron,
.xpo-VerticalTabs .mat-tab-header-pagination-chevron,
.xpo-PrimaryTabs .mat-tab-header-pagination-chevron {
  height: 8px;
  width: 8px;
  border-color: #4a4a4a;
}
.xpo-AlternateTabs .mat-tab-header-pagination-chevron:hover:not(.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron),
.xpo-PillTabGroup .mat-tab-header-pagination-chevron:hover:not(.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron),
.xpo-VerticalTabs .mat-tab-header-pagination-chevron:hover:not(.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron),
.xpo-PrimaryTabs .mat-tab-header-pagination-chevron:hover:not(.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron) {
  border-color: #212121;
}
.xpo-AlternateTabs .mat-tab-label-content,
.xpo-PillTabGroup .mat-tab-label-content,
.xpo-VerticalTabs .mat-tab-label-content,
.xpo-PrimaryTabs .mat-tab-label-content {
  gap: 8px;
}
.xpo-AlternateTabs .mat-tab-label-content > .mat-icon,
.xpo-PillTabGroup .mat-tab-label-content > .mat-icon,
.xpo-VerticalTabs .mat-tab-label-content > .mat-icon,
.xpo-PrimaryTabs .mat-tab-label-content > .mat-icon {
  font-size: 1.428rem;
  width: 20px;
  height: 20px;
}
.xpo-AlternateTabs .mat-tab-label-content .mat-badge.mat-badge-overlap,
.xpo-PillTabGroup .mat-tab-label-content .mat-badge.mat-badge-overlap,
.xpo-VerticalTabs .mat-tab-label-content .mat-badge.mat-badge-overlap,
.xpo-PrimaryTabs .mat-tab-label-content .mat-badge.mat-badge-overlap {
  display: flex;
  gap: 8px;
}
.xpo-AlternateTabs .mat-tab-label-content .mat-badge-content,
.xpo-AlternateTabs .mat-tab-label-content .mat-chip,
.xpo-PillTabGroup .mat-tab-label-content .mat-badge-content,
.xpo-PillTabGroup .mat-tab-label-content .mat-chip,
.xpo-VerticalTabs .mat-tab-label-content .mat-badge-content,
.xpo-VerticalTabs .mat-tab-label-content .mat-chip,
.xpo-PrimaryTabs .mat-tab-label-content .mat-badge-content,
.xpo-PrimaryTabs .mat-tab-label-content .mat-chip {
  align-items: center;
  background-color: #f6f6f6;
  border: 1px solid #d8d8d8;
  border-radius: 10px;
  color: #000000;
  display: flex;
  font-weight: 900;
  justify-content: center;
  height: 20px;
  line-height: 12px;
  min-width: 30px;
  overflow: initial;
  padding: 4px 8px;
  position: initial;
  width: 100%;
}
.xpo-AlternateTabs .mat-tab-list .mat-ink-bar,
.xpo-PillTabGroup .mat-tab-list .mat-ink-bar,
.xpo-VerticalTabs .mat-tab-list .mat-ink-bar,
.xpo-PrimaryTabs .mat-tab-list .mat-ink-bar {
  display: none;
}
.xpo-AlternateTabs .mat-tab-header .mat-tab-label,
.xpo-AlternateTabs .mat-tab-links .mat-tab-link,
.xpo-AlternateTabs .mat-tab-labels .mat-tab-label,
.xpo-AlternateTabs .mat-tab-nav-bar .mat-tab-link,
.xpo-PillTabGroup .mat-tab-header .mat-tab-label,
.xpo-PillTabGroup .mat-tab-links .mat-tab-link,
.xpo-PillTabGroup .mat-tab-labels .mat-tab-label,
.xpo-PillTabGroup .mat-tab-nav-bar .mat-tab-link,
.xpo-VerticalTabs .mat-tab-header .mat-tab-label,
.xpo-VerticalTabs .mat-tab-links .mat-tab-link,
.xpo-VerticalTabs .mat-tab-labels .mat-tab-label,
.xpo-VerticalTabs .mat-tab-nav-bar .mat-tab-link,
.xpo-PrimaryTabs .mat-tab-header .mat-tab-label,
.xpo-PrimaryTabs .mat-tab-links .mat-tab-link,
.xpo-PrimaryTabs .mat-tab-labels .mat-tab-label,
.xpo-PrimaryTabs .mat-tab-nav-bar .mat-tab-link {
  background-color: #ffffff;
  border-left: 1px solid #d8d8d8;
  border-top: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
  color: #212121;
  font-weight: 700;
  height: 40px;
  line-height: 1.429rem;
  min-width: 0;
  opacity: 1;
  padding: 10px 16px;
}
.xpo-AlternateTabs .mat-tab-header .mat-tab-label.mat-tab-label-active,
.xpo-AlternateTabs .mat-tab-links .mat-tab-link.mat-tab-label-active,
.xpo-AlternateTabs .mat-tab-labels .mat-tab-label.mat-tab-label-active,
.xpo-AlternateTabs .mat-tab-nav-bar .mat-tab-link.mat-tab-label-active,
.xpo-PillTabGroup .mat-tab-header .mat-tab-label.mat-tab-label-active,
.xpo-PillTabGroup .mat-tab-links .mat-tab-link.mat-tab-label-active,
.xpo-PillTabGroup .mat-tab-labels .mat-tab-label.mat-tab-label-active,
.xpo-PillTabGroup .mat-tab-nav-bar .mat-tab-link.mat-tab-label-active,
.xpo-VerticalTabs .mat-tab-header .mat-tab-label.mat-tab-label-active,
.xpo-VerticalTabs .mat-tab-links .mat-tab-link.mat-tab-label-active,
.xpo-VerticalTabs .mat-tab-labels .mat-tab-label.mat-tab-label-active,
.xpo-VerticalTabs .mat-tab-nav-bar .mat-tab-link.mat-tab-label-active,
.xpo-PrimaryTabs .mat-tab-header .mat-tab-label.mat-tab-label-active,
.xpo-PrimaryTabs .mat-tab-links .mat-tab-link.mat-tab-label-active,
.xpo-PrimaryTabs .mat-tab-labels .mat-tab-label.mat-tab-label-active,
.xpo-PrimaryTabs .mat-tab-nav-bar .mat-tab-link.mat-tab-label-active {
  border-bottom: 1px solid #ffffff;
  border-top: 4px solid #0d47a1;
  overflow: inherit;
}
.xpo-AlternateTabs .mat-tab-header .mat-tab-label.mat-tab-label-active .mat-tab-label-content,
.xpo-AlternateTabs .mat-tab-links .mat-tab-link.mat-tab-label-active .mat-tab-label-content,
.xpo-AlternateTabs .mat-tab-labels .mat-tab-label.mat-tab-label-active .mat-tab-label-content,
.xpo-AlternateTabs .mat-tab-nav-bar .mat-tab-link.mat-tab-label-active .mat-tab-label-content,
.xpo-PillTabGroup .mat-tab-header .mat-tab-label.mat-tab-label-active .mat-tab-label-content,
.xpo-PillTabGroup .mat-tab-links .mat-tab-link.mat-tab-label-active .mat-tab-label-content,
.xpo-PillTabGroup .mat-tab-labels .mat-tab-label.mat-tab-label-active .mat-tab-label-content,
.xpo-PillTabGroup .mat-tab-nav-bar .mat-tab-link.mat-tab-label-active .mat-tab-label-content,
.xpo-VerticalTabs .mat-tab-header .mat-tab-label.mat-tab-label-active .mat-tab-label-content,
.xpo-VerticalTabs .mat-tab-links .mat-tab-link.mat-tab-label-active .mat-tab-label-content,
.xpo-VerticalTabs .mat-tab-labels .mat-tab-label.mat-tab-label-active .mat-tab-label-content,
.xpo-VerticalTabs .mat-tab-nav-bar .mat-tab-link.mat-tab-label-active .mat-tab-label-content,
.xpo-PrimaryTabs .mat-tab-header .mat-tab-label.mat-tab-label-active .mat-tab-label-content,
.xpo-PrimaryTabs .mat-tab-links .mat-tab-link.mat-tab-label-active .mat-tab-label-content,
.xpo-PrimaryTabs .mat-tab-labels .mat-tab-label.mat-tab-label-active .mat-tab-label-content,
.xpo-PrimaryTabs .mat-tab-nav-bar .mat-tab-link.mat-tab-label-active .mat-tab-label-content {
  margin-bottom: 3px;
}
.xpo-AlternateTabs .mat-tab-header .mat-tab-label.mat-tab-label-active .mat-badge-content,
.xpo-AlternateTabs .mat-tab-header .mat-tab-label.mat-tab-label-active .mat-chip,
.xpo-AlternateTabs .mat-tab-links .mat-tab-link.mat-tab-label-active .mat-badge-content,
.xpo-AlternateTabs .mat-tab-links .mat-tab-link.mat-tab-label-active .mat-chip,
.xpo-AlternateTabs .mat-tab-labels .mat-tab-label.mat-tab-label-active .mat-badge-content,
.xpo-AlternateTabs .mat-tab-labels .mat-tab-label.mat-tab-label-active .mat-chip,
.xpo-AlternateTabs .mat-tab-nav-bar .mat-tab-link.mat-tab-label-active .mat-badge-content,
.xpo-AlternateTabs .mat-tab-nav-bar .mat-tab-link.mat-tab-label-active .mat-chip,
.xpo-PillTabGroup .mat-tab-header .mat-tab-label.mat-tab-label-active .mat-badge-content,
.xpo-PillTabGroup .mat-tab-header .mat-tab-label.mat-tab-label-active .mat-chip,
.xpo-PillTabGroup .mat-tab-links .mat-tab-link.mat-tab-label-active .mat-badge-content,
.xpo-PillTabGroup .mat-tab-links .mat-tab-link.mat-tab-label-active .mat-chip,
.xpo-PillTabGroup .mat-tab-labels .mat-tab-label.mat-tab-label-active .mat-badge-content,
.xpo-PillTabGroup .mat-tab-labels .mat-tab-label.mat-tab-label-active .mat-chip,
.xpo-PillTabGroup .mat-tab-nav-bar .mat-tab-link.mat-tab-label-active .mat-badge-content,
.xpo-PillTabGroup .mat-tab-nav-bar .mat-tab-link.mat-tab-label-active .mat-chip,
.xpo-VerticalTabs .mat-tab-header .mat-tab-label.mat-tab-label-active .mat-badge-content,
.xpo-VerticalTabs .mat-tab-header .mat-tab-label.mat-tab-label-active .mat-chip,
.xpo-VerticalTabs .mat-tab-links .mat-tab-link.mat-tab-label-active .mat-badge-content,
.xpo-VerticalTabs .mat-tab-links .mat-tab-link.mat-tab-label-active .mat-chip,
.xpo-VerticalTabs .mat-tab-labels .mat-tab-label.mat-tab-label-active .mat-badge-content,
.xpo-VerticalTabs .mat-tab-labels .mat-tab-label.mat-tab-label-active .mat-chip,
.xpo-VerticalTabs .mat-tab-nav-bar .mat-tab-link.mat-tab-label-active .mat-badge-content,
.xpo-VerticalTabs .mat-tab-nav-bar .mat-tab-link.mat-tab-label-active .mat-chip,
.xpo-PrimaryTabs .mat-tab-header .mat-tab-label.mat-tab-label-active .mat-badge-content,
.xpo-PrimaryTabs .mat-tab-header .mat-tab-label.mat-tab-label-active .mat-chip,
.xpo-PrimaryTabs .mat-tab-links .mat-tab-link.mat-tab-label-active .mat-badge-content,
.xpo-PrimaryTabs .mat-tab-links .mat-tab-link.mat-tab-label-active .mat-chip,
.xpo-PrimaryTabs .mat-tab-labels .mat-tab-label.mat-tab-label-active .mat-badge-content,
.xpo-PrimaryTabs .mat-tab-labels .mat-tab-label.mat-tab-label-active .mat-chip,
.xpo-PrimaryTabs .mat-tab-nav-bar .mat-tab-link.mat-tab-label-active .mat-badge-content,
.xpo-PrimaryTabs .mat-tab-nav-bar .mat-tab-link.mat-tab-label-active .mat-chip {
  background-color: #4a4a4a;
  border: 1px solid #4a4a4a;
  color: #ffffff;
}
.xpo-AlternateTabs .mat-tab-header .mat-tab-label.mat-tab-disabled,
.xpo-AlternateTabs .mat-tab-links .mat-tab-link.mat-tab-disabled,
.xpo-AlternateTabs .mat-tab-labels .mat-tab-label.mat-tab-disabled,
.xpo-AlternateTabs .mat-tab-nav-bar .mat-tab-link.mat-tab-disabled,
.xpo-PillTabGroup .mat-tab-header .mat-tab-label.mat-tab-disabled,
.xpo-PillTabGroup .mat-tab-links .mat-tab-link.mat-tab-disabled,
.xpo-PillTabGroup .mat-tab-labels .mat-tab-label.mat-tab-disabled,
.xpo-PillTabGroup .mat-tab-nav-bar .mat-tab-link.mat-tab-disabled,
.xpo-VerticalTabs .mat-tab-header .mat-tab-label.mat-tab-disabled,
.xpo-VerticalTabs .mat-tab-links .mat-tab-link.mat-tab-disabled,
.xpo-VerticalTabs .mat-tab-labels .mat-tab-label.mat-tab-disabled,
.xpo-VerticalTabs .mat-tab-nav-bar .mat-tab-link.mat-tab-disabled,
.xpo-PrimaryTabs .mat-tab-header .mat-tab-label.mat-tab-disabled,
.xpo-PrimaryTabs .mat-tab-links .mat-tab-link.mat-tab-disabled,
.xpo-PrimaryTabs .mat-tab-labels .mat-tab-label.mat-tab-disabled,
.xpo-PrimaryTabs .mat-tab-nav-bar .mat-tab-link.mat-tab-disabled {
  background-color: #f6f6f6;
  color: #d8d8d8;
}
.xpo-AlternateTabs .mat-tab-header .mat-tab-label:last-child,
.xpo-AlternateTabs .mat-tab-links .mat-tab-link:last-child,
.xpo-AlternateTabs .mat-tab-labels .mat-tab-label:last-child,
.xpo-AlternateTabs .mat-tab-nav-bar .mat-tab-link:last-child,
.xpo-PillTabGroup .mat-tab-header .mat-tab-label:last-child,
.xpo-PillTabGroup .mat-tab-links .mat-tab-link:last-child,
.xpo-PillTabGroup .mat-tab-labels .mat-tab-label:last-child,
.xpo-PillTabGroup .mat-tab-nav-bar .mat-tab-link:last-child,
.xpo-VerticalTabs .mat-tab-header .mat-tab-label:last-child,
.xpo-VerticalTabs .mat-tab-links .mat-tab-link:last-child,
.xpo-VerticalTabs .mat-tab-labels .mat-tab-label:last-child,
.xpo-VerticalTabs .mat-tab-nav-bar .mat-tab-link:last-child,
.xpo-PrimaryTabs .mat-tab-header .mat-tab-label:last-child,
.xpo-PrimaryTabs .mat-tab-links .mat-tab-link:last-child,
.xpo-PrimaryTabs .mat-tab-labels .mat-tab-label:last-child,
.xpo-PrimaryTabs .mat-tab-nav-bar .mat-tab-link:last-child {
  border-right: 1px solid #d8d8d8;
}
.xpo-AlternateTabs .mat-tab-header .mat-tab-label:hover:not(.mat-tab-disabled):not(.mat-tab-label-active),
.xpo-AlternateTabs .mat-tab-links .mat-tab-link:hover:not(.mat-tab-disabled):not(.mat-tab-label-active),
.xpo-AlternateTabs .mat-tab-labels .mat-tab-label:hover:not(.mat-tab-disabled):not(.mat-tab-label-active),
.xpo-AlternateTabs .mat-tab-nav-bar .mat-tab-link:hover:not(.mat-tab-disabled):not(.mat-tab-label-active),
.xpo-PillTabGroup .mat-tab-header .mat-tab-label:hover:not(.mat-tab-disabled):not(.mat-tab-label-active),
.xpo-PillTabGroup .mat-tab-links .mat-tab-link:hover:not(.mat-tab-disabled):not(.mat-tab-label-active),
.xpo-PillTabGroup .mat-tab-labels .mat-tab-label:hover:not(.mat-tab-disabled):not(.mat-tab-label-active),
.xpo-PillTabGroup .mat-tab-nav-bar .mat-tab-link:hover:not(.mat-tab-disabled):not(.mat-tab-label-active),
.xpo-VerticalTabs .mat-tab-header .mat-tab-label:hover:not(.mat-tab-disabled):not(.mat-tab-label-active),
.xpo-VerticalTabs .mat-tab-links .mat-tab-link:hover:not(.mat-tab-disabled):not(.mat-tab-label-active),
.xpo-VerticalTabs .mat-tab-labels .mat-tab-label:hover:not(.mat-tab-disabled):not(.mat-tab-label-active),
.xpo-VerticalTabs .mat-tab-nav-bar .mat-tab-link:hover:not(.mat-tab-disabled):not(.mat-tab-label-active),
.xpo-PrimaryTabs .mat-tab-header .mat-tab-label:hover:not(.mat-tab-disabled):not(.mat-tab-label-active),
.xpo-PrimaryTabs .mat-tab-links .mat-tab-link:hover:not(.mat-tab-disabled):not(.mat-tab-label-active),
.xpo-PrimaryTabs .mat-tab-labels .mat-tab-label:hover:not(.mat-tab-disabled):not(.mat-tab-label-active),
.xpo-PrimaryTabs .mat-tab-nav-bar .mat-tab-link:hover:not(.mat-tab-disabled):not(.mat-tab-label-active) {
  border-top: 4px solid #2196f3;
}
.xpo-AlternateTabs .mat-tab-header .mat-tab-label:hover:not(.mat-tab-disabled):not(.mat-tab-label-active) .mat-tab-label-content,
.xpo-AlternateTabs .mat-tab-links .mat-tab-link:hover:not(.mat-tab-disabled):not(.mat-tab-label-active) .mat-tab-label-content,
.xpo-AlternateTabs .mat-tab-labels .mat-tab-label:hover:not(.mat-tab-disabled):not(.mat-tab-label-active) .mat-tab-label-content,
.xpo-AlternateTabs .mat-tab-nav-bar .mat-tab-link:hover:not(.mat-tab-disabled):not(.mat-tab-label-active) .mat-tab-label-content,
.xpo-PillTabGroup .mat-tab-header .mat-tab-label:hover:not(.mat-tab-disabled):not(.mat-tab-label-active) .mat-tab-label-content,
.xpo-PillTabGroup .mat-tab-links .mat-tab-link:hover:not(.mat-tab-disabled):not(.mat-tab-label-active) .mat-tab-label-content,
.xpo-PillTabGroup .mat-tab-labels .mat-tab-label:hover:not(.mat-tab-disabled):not(.mat-tab-label-active) .mat-tab-label-content,
.xpo-PillTabGroup .mat-tab-nav-bar .mat-tab-link:hover:not(.mat-tab-disabled):not(.mat-tab-label-active) .mat-tab-label-content,
.xpo-VerticalTabs .mat-tab-header .mat-tab-label:hover:not(.mat-tab-disabled):not(.mat-tab-label-active) .mat-tab-label-content,
.xpo-VerticalTabs .mat-tab-links .mat-tab-link:hover:not(.mat-tab-disabled):not(.mat-tab-label-active) .mat-tab-label-content,
.xpo-VerticalTabs .mat-tab-labels .mat-tab-label:hover:not(.mat-tab-disabled):not(.mat-tab-label-active) .mat-tab-label-content,
.xpo-VerticalTabs .mat-tab-nav-bar .mat-tab-link:hover:not(.mat-tab-disabled):not(.mat-tab-label-active) .mat-tab-label-content,
.xpo-PrimaryTabs .mat-tab-header .mat-tab-label:hover:not(.mat-tab-disabled):not(.mat-tab-label-active) .mat-tab-label-content,
.xpo-PrimaryTabs .mat-tab-links .mat-tab-link:hover:not(.mat-tab-disabled):not(.mat-tab-label-active) .mat-tab-label-content,
.xpo-PrimaryTabs .mat-tab-labels .mat-tab-label:hover:not(.mat-tab-disabled):not(.mat-tab-label-active) .mat-tab-label-content,
.xpo-PrimaryTabs .mat-tab-nav-bar .mat-tab-link:hover:not(.mat-tab-disabled):not(.mat-tab-label-active) .mat-tab-label-content {
  margin-bottom: 3px;
}

.mat-tab-nav-bar.xpo-AlternateTabs {
  border-bottom: none;
}

.xpo-AlternateTabs .mat-tab-labels::before {
  content: "";
  border-bottom: 1px solid #d8d8d8;
  width: 16px;
}
.xpo-AlternateTabs .mat-tab-labels::after {
  content: "";
  border-bottom: 1px solid #d8d8d8;
  flex-shrink: 0;
}

.xpo-PillTabGroup .mat-tab-header .mat-tab-label-container .mat-tab-labels {
  gap: 4px;
}
.xpo-PillTabGroup .mat-tab-header .mat-tab-label-container .mat-tab-labels .mat-tab-label,
.xpo-PillTabGroup .mat-tab-header .mat-tab-nav-bar .mat-tab-link {
  padding: 6px 16px;
  border-radius: 20px;
  height: 32px;
  border: 0;
  font-weight: 400;
  line-height: 20px;
  color: #1976d2;
}
.xpo-PillTabGroup .mat-tab-header .mat-tab-label-container .mat-tab-labels .mat-tab-label.mat-tab-label-active,
.xpo-PillTabGroup .mat-tab-header .mat-tab-nav-bar .mat-tab-link.mat-tab-label-active {
  background-color: #e4effa;
  color: #212121;
}
.xpo-PillTabGroup .mat-tab-header .mat-tab-label-container .mat-tab-labels .mat-tab-label.mat-tab-label-active .mat-tab-label-content,
.xpo-PillTabGroup .mat-tab-header .mat-tab-nav-bar .mat-tab-link.mat-tab-label-active .mat-tab-label-content {
  margin-bottom: 0;
}
.xpo-PillTabGroup .mat-tab-header .mat-tab-label-container .mat-tab-labels .mat-tab-label.mat-tab-disabled,
.xpo-PillTabGroup .mat-tab-header .mat-tab-nav-bar .mat-tab-link.mat-tab-disabled {
  color: #bdbdbd;
}
.xpo-PillTabGroup .mat-tab-header .mat-tab-label-container .mat-tab-labels .mat-tab-label:hover:not(.mat-tab-disabled):not(.mat-tab-label-active),
.xpo-PillTabGroup .mat-tab-header .mat-tab-nav-bar .mat-tab-link:hover:not(.mat-tab-disabled):not(.mat-tab-label-active) {
  border: 0;
  color: #0d47a1;
}
.xpo-PillTabGroup .mat-tab-header .mat-tab-label-container .mat-tab-labels .mat-tab-label:hover:not(.mat-tab-disabled):not(.mat-tab-label-active) .mat-tab-label-content,
.xpo-PillTabGroup .mat-tab-header .mat-tab-nav-bar .mat-tab-link:hover:not(.mat-tab-disabled):not(.mat-tab-label-active) .mat-tab-label-content {
  margin-bottom: 0;
}

.mat-tab-group.mat-primary.xpo-VerticalTabs {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
}
.mat-tab-group.mat-primary.xpo-VerticalTabs .mat-tab-header .mat-tab-label-container .mat-tab-list {
  flex-grow: 0;
}
.mat-tab-group.mat-primary.xpo-VerticalTabs .mat-tab-header .mat-tab-label-container .mat-tab-labels {
  display: flex;
  flex-direction: column;
}
.mat-tab-group.mat-primary.xpo-VerticalTabs .mat-tab-header .mat-tab-label-container .mat-tab-labels .mat-tab-label {
  border: 1px solid #d8d8d8;
  border-top: 0;
  height: 32px;
  line-height: 1.429rem;
  justify-content: start;
  padding: 6px 16px;
}
.mat-tab-group.mat-primary.xpo-VerticalTabs .mat-tab-header .mat-tab-label-container .mat-tab-labels .mat-tab-label:first-child {
  border-top: 1px solid #d8d8d8;
}
.mat-tab-group.mat-primary.xpo-VerticalTabs .mat-tab-header .mat-tab-label-container .mat-tab-labels .mat-tab-label-content {
  font-weight: 400;
}
.mat-tab-group.mat-primary.xpo-VerticalTabs .mat-tab-header .mat-tab-label-container .mat-tab-labels .mat-tab-label-active {
  border-left: 4px solid #0d47a1;
}
.mat-tab-group.mat-primary.xpo-VerticalTabs .mat-tab-header .mat-tab-label-container .mat-tab-labels .mat-tab-label.mat-tab-label-active .mat-tab-label-content {
  font-weight: 700;
  margin-bottom: 0;
  margin-left: -3px;
}
.mat-tab-group.mat-primary.xpo-VerticalTabs .mat-tab-header .mat-tab-label-container .mat-tab-labels .mat-tab-label:hover:first-child {
  border-top: 1px solid #d8d8d8;
  border-left: 4px solid #2196f3;
}
.mat-tab-group.mat-primary.xpo-VerticalTabs .mat-tab-header .mat-tab-label-container .mat-tab-labels .mat-tab-label:hover:first-child .mat-tab-label-content {
  margin-bottom: 0;
  margin-left: -3px;
}
.mat-tab-group.mat-primary.xpo-VerticalTabs .mat-tab-header .mat-tab-label-container .mat-tab-labels .mat-tab-label:hover:not(.mat-tab-disabled):not(:first-child) {
  border-top: 0;
  border-left: 4px solid #2196f3;
}
.mat-tab-group.mat-primary.xpo-VerticalTabs .mat-tab-header .mat-tab-label-container .mat-tab-labels .mat-tab-label:hover:not(.mat-tab-disabled):not(:first-child) .mat-tab-label-content {
  margin-bottom: 0;
  margin-left: -3px;
}

/*
    1. Moves the select to sit right under the trigger
*/
.xpo-Footer {
  width: 100%;
}
.xpo-Footer-navbar > :nth-child(n) {
  margin: 0 12px;
}
.xpo-Footer-navbar > :nth-child(n):last-child {
  margin-right: 16px;
}
.xpo-Footer-bold-text {
  font-weight: 700;
}
.xpo-Footer--sticky {
  bottom: 0;
  position: sticky;
}

.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-h1,
.mat-headline,
.mat-typography .mat-h1,
.mat-typography .mat-headline,
.mat-typography h1 {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2,
.mat-title,
.mat-typography .mat-h2,
.mat-typography .mat-title,
.mat-typography h2 {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subheading-2,
.mat-typography .mat-h3,
.mat-typography .mat-subheading-2,
.mat-typography h3 {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4,
.mat-subheading-1,
.mat-typography .mat-h4,
.mat-typography .mat-subheading-1,
.mat-typography h4 {
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-body-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-body-2 {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-body,
.mat-body-1,
.mat-typography .mat-body,
.mat-typography .mat-body-1,
.mat-typography {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-body p,
.mat-body-1 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-1 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-display-4,
.mat-typography .mat-display-4 {
  font-size: 112px;
  font-weight: 300;
  line-height: 112px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}

.mat-display-3,
.mat-typography .mat-display-3 {
  font-size: 56px;
  font-weight: 400;
  line-height: 56px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}

.mat-display-2,
.mat-typography .mat-display-2 {
  font-size: 45px;
  font-weight: 400;
  line-height: 48px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}

.mat-display-1,
.mat-typography .mat-display-1 {
  font-size: 34px;
  font-weight: 400;
  line-height: 40px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-bottom-sheet-container {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-button-toggle {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-calendar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.mat-expansion-panel-content {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0;
}

.mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
}

.mat-optgroup-label {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-datepicker-content .mdc-button {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Roboto, "Helvetica Neue", sans-serif));
  font-size: var(--mdc-typography-button-font-size, 14px);
  line-height: var(--mdc-typography-button-line-height, 14px);
  font-weight: var(--mdc-typography-button-font-weight, 500);
  letter-spacing: var(--mdc-typography-button-letter-spacing, normal);
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: var(--mdc-typography-button-text-transform, none);
}
.mat-card {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}

.mat-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-can-float .mat-input-server[label] + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  width: 133.3333333333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  width: 133.3333433333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label] + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  width: 133.3333533333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label] + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label] + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label] + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-select {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-checkbox {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-dialog-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-slide-toggle-content {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tab-group {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tab-label, .mat-tab-link {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-radio-button {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-menu-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.mat-list-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
}

.mat-simple-snackbar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-table {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 14px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #0868ac;
}

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #4a4a4a;
}

.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #d50000;
}

.mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-pseudo-checkbox-full {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #0868ac;
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #0868ac;
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #4a4a4a;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #4a4a4a;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #4a4a4a;
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #4a4a4a;
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #d50000;
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #d50000;
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #b0b0b0;
}
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #b0b0b0;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #4a4a4a;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(74, 74, 74, 0.54);
}
.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #4a4a4a;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #0868ac;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(8, 104, 172, 0.54);
}
.mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #0868ac;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #d50000;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(213, 0, 0, 0.54);
}
.mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #d50000;
}
.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}

.mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}

.mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-theme-loaded-marker {
  display: none;
}

.xpo-Card {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.87);
}

.xpo-Button,
.xpo-Button .mat-button-toggle-button {
  text-transform: uppercase;
}

[mat-flat-button].mat-flat-button,
[mat-stroked-button].mat-stroked-button {
  border-radius: 2px;
  font-size: 1.143rem;
  font-weight: 500;
  height: 40px;
  line-height: 24px;
  padding: 8px 20px;
  min-width: auto;
  transition: color 200ms ease, background-color 200ms ease, border-color 200ms ease;
}

mat-button-toggle.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
mat-button-toggle-group.mat-button-toggle-group.mat-button-toggle-group-appearance-standard {
  height: 40px;
}
mat-button-toggle.mat-button-toggle-standalone.mat-button-toggle-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle,
mat-button-toggle-group.mat-button-toggle-group.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-top-width: 2px;
}
mat-button-toggle.mat-button-toggle-standalone.mat-button-toggle-appearance-standard .mat-button-toggle-appearance-standard.mat-button-toggle,
mat-button-toggle.mat-button-toggle-standalone.mat-button-toggle-appearance-standard .mat-button-toggle-button,
mat-button-toggle-group.mat-button-toggle-group.mat-button-toggle-group-appearance-standard .mat-button-toggle-appearance-standard.mat-button-toggle,
mat-button-toggle-group.mat-button-toggle-group.mat-button-toggle-group-appearance-standard .mat-button-toggle-button {
  border-width: 2px;
}
mat-button-toggle.mat-button-toggle-standalone.mat-button-toggle-appearance-standard .mat-button-toggle-button,
mat-button-toggle-group.mat-button-toggle-group.mat-button-toggle-group-appearance-standard .mat-button-toggle-button {
  font-size: 1.143rem;
  height: 100%;
}

mat-button-toggle.mat-button-toggle-standalone.mat-button-toggle-appearance-standard {
  border-width: 2px;
}

mat-button-toggle-group.mat-button-toggle-group {
  border-width: 0;
}

[mat-flat-button].mat-flat-button {
  border-style: solid;
  border-width: 2px;
  box-shadow: none !important;
  color: #ffffff;
}
[mat-flat-button].mat-flat-button {
  background-color: #1976d2;
  border-color: #1976d2;
}
[mat-flat-button].mat-flat-button:hover {
  background-color: #2196f3;
  border-color: #2196f3;
}
[mat-flat-button].mat-flat-button:focus, [mat-flat-button].mat-flat-button:active {
  background-color: #0d47a1;
  border-color: #0d47a1;
}
[mat-flat-button].mat-flat-button[disabled], [mat-flat-button].mat-flat-button[disabled]:active, [mat-flat-button].mat-flat-button[disabled]:hover, [mat-flat-button].mat-flat-button[disabled]:focus {
  background-color: #d8d8d8;
  border-color: #d8d8d8;
  color: #ffffff;
  cursor: not-allowed;
}

.mat-stroked-button {
  background-color: #ffffff;
  border-color: #1976d2;
  color: #0d47a1;
}
.mat-stroked-button:hover {
  background-color: #e4effa;
  border-color: #1976d2;
  color: #1976d2;
}
.mat-stroked-button:focus, .mat-stroked-button:active {
  background-color: #e4effa;
  border-color: #2196f3;
  color: #2196f3;
}
.mat-stroked-button[disabled], .mat-stroked-button[disabled]:active, .mat-stroked-button[disabled]:hover, .mat-stroked-button[disabled]:focus {
  background-color: #fafafa;
  border-color: #bdbdbd;
  color: #bdbdbd;
  cursor: not-allowed;
}

[mat-flat-button].mat-flat-button,
[mat-stroked-button].mat-stroked-button {
  align-items: center;
  display: inline-flex;
  justify-content: center;
}

[mat-flat-button].mat-flat-button.xpo-SmallButton,
[mat-stroked-button].mat-stroked-button.xpo-SmallButton {
  border-width: 1px;
  font-size: 1rem;
  height: 32px;
  padding: 4px 20px;
}
[mat-flat-button].mat-flat-button.xpo-SmallButton .mat-icon,
[mat-stroked-button].mat-stroked-button.xpo-SmallButton .mat-icon {
  font-size: 20px;
}
[mat-flat-button].mat-flat-button.xpo-SmallButton .mat-button-wrapper,
[mat-stroked-button].mat-stroked-button.xpo-SmallButton .mat-button-wrapper {
  line-height: 28px;
}

mat-button-toggle-group.mat-button-toggle-group.mat-button-toggle-group-appearance-standard.xpo-SmallButton,
mat-button-toggle.mat-button-toggle-standalone.mat-button-toggle-appearance-standard.xpo-SmallButton {
  height: 32px;
}
mat-button-toggle-group.mat-button-toggle-group.mat-button-toggle-group-appearance-standard.xpo-SmallButton .mat-button-toggle, mat-button-toggle-group.mat-button-toggle-group.mat-button-toggle-group-appearance-standard.xpo-SmallButton.mat-button-toggle,
mat-button-toggle.mat-button-toggle-standalone.mat-button-toggle-appearance-standard.xpo-SmallButton .mat-button-toggle,
mat-button-toggle.mat-button-toggle-standalone.mat-button-toggle-appearance-standard.xpo-SmallButton.mat-button-toggle {
  border-width: 1px;
}
mat-button-toggle-group.mat-button-toggle-group.mat-button-toggle-group-appearance-standard.xpo-SmallButton .mat-button-toggle .mat-button-toggle-button, mat-button-toggle-group.mat-button-toggle-group.mat-button-toggle-group-appearance-standard.xpo-SmallButton.mat-button-toggle .mat-button-toggle-button,
mat-button-toggle.mat-button-toggle-standalone.mat-button-toggle-appearance-standard.xpo-SmallButton .mat-button-toggle .mat-button-toggle-button,
mat-button-toggle.mat-button-toggle-standalone.mat-button-toggle-appearance-standard.xpo-SmallButton.mat-button-toggle .mat-button-toggle-button {
  font-size: 1rem;
  height: 100%;
}

[mat-flat-button].mat-flat-button.xpo-LargeButton,
[mat-stroked-button].mat-stroked-button.xpo-LargeButton,
mat-button-toggle-group.mat-button-toggle-group.mat-button-toggle-group-appearance-standard.xpo-LargeButton,
mat-button-toggle.mat-button-toggle-standalone.mat-button-toggle-appearance-standard.xpo-LargeButton {
  font-size: 1.428rem;
  font-weight: 500;
  height: 56px;
  line-height: normal;
  padding: 16px 20px;
}
[mat-flat-button].mat-flat-button.xpo-LargeButton .mat-button-toggle-button,
[mat-stroked-button].mat-stroked-button.xpo-LargeButton .mat-button-toggle-button,
mat-button-toggle-group.mat-button-toggle-group.mat-button-toggle-group-appearance-standard.xpo-LargeButton .mat-button-toggle-button,
mat-button-toggle.mat-button-toggle-standalone.mat-button-toggle-appearance-standard.xpo-LargeButton .mat-button-toggle-button {
  font-size: 1.285rem;
  height: 100%;
}

.xpo-ActionButton {
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  color: #4a4a4a;
  height: 32px;
  width: 32px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.xpo-ActionButton > .mat-icon {
  width: 20px;
  height: 20px;
  font-size: 20px;
}
.xpo-ActionButton:focus, .xpo-ActionButton:active {
  border: 1px solid #d8d8d8;
  color: #1976d2;
  outline: none;
}
.xpo-ActionButton:hover {
  color: #212121;
}
.xpo-ActionButton[disabled], .xpo-ActionButton[disabled]:active, .xpo-ActionButton[disabled]:hover, .xpo-ActionButton[disabled]:focus {
  background-color: #ffffff;
  border-color: #d8d8d8;
  color: #bdbdbd;
  cursor: not-allowed;
}

.mat-button-focus-overlay,
.mat-button-toggle-focus-overlay {
  opacity: 0 !important;
}

.cdk-overlay-container .cdk-global-overlay-wrapper.xpo-ApplicationSwitcher-dialog-wrapper {
  height: auto;
  max-height: 100%;
}
.cdk-overlay-container .cdk-global-overlay-wrapper.xpo-ApplicationSwitcher-dialog-wrapper .cdk-overlay-pane.xpo-ApplicationSwitcher-dialog {
  width: 100%;
  background-color: #ffffff;
  border-bottom: solid 1px #d8d8d8;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 100px;
}

.xpo-ApplicationSwitcherContent {
  overflow: auto;
}
.xpo-ApplicationSwitcherContent .xpo-ApplicationCategory .xpo-Application:last-of-type .xpo-Application-content:hover {
  border-bottom: none;
}

.mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}

.mat-autocomplete-panel-above:nth-child(1) {
  border-top: 1px solid #d8d8d8;
}
.mat-autocomplete-panel-above .mat-autocomplete-panel.mat-autocomplete-visible {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.mat-autocomplete-panel-above .mat-autocomplete-panel.mat-autocomplete-visible:last-of-type {
  border-bottom: 0;
}

.mat-form-field-suffix .mat-icon {
  cursor: pointer;
}

.mat-autocomplete-panel.mat-autocomplete-visible {
  border: 1px solid #d8d8d8;
  border-radius: 0;
  border-top: none;
  box-shadow: none;
}
.mat-autocomplete-panel.mat-autocomplete-visible .mat-option {
  border-bottom: 1px solid #d8d8d8;
  color: #000000;
  font-size: 14px;
  height: 30px;
  line-height: 1.429rem;
  padding: 0 8px;
}
.mat-autocomplete-panel.mat-autocomplete-visible .mat-option:hover {
  background-color: #e4effa;
}
.mat-autocomplete-panel.mat-autocomplete-visible .mat-option.mat-active {
  background: #e4effa;
}
.mat-autocomplete-panel.mat-autocomplete-visible .mat-option:last-of-type {
  border-bottom: 0;
}
.mat-autocomplete-panel.mat-autocomplete-visible .mat-option .mat-option-text {
  align-items: center;
  display: inline-flex;
}
.mat-autocomplete-panel.mat-autocomplete-visible .mat-option .xpo-Icon {
  margin-right: 4px;
}
.mat-autocomplete-panel.mat-autocomplete-visible .mat-option.xpo-Option--twoLines {
  height: 45px;
  line-height: 1.429rem;
  padding: 6px 8px;
}
.mat-autocomplete-panel.mat-autocomplete-visible .mat-option.xpo-Option--twoLines .mat-option-text {
  align-items: center;
  display: flex;
}
.mat-autocomplete-panel.mat-autocomplete-visible .mat-option.xpo-Option--twoLines .mat-option-text .xpo-Select-twoLinesContainer {
  flex-grow: 1;
  margin-left: 0;
  overflow: hidden;
}
.mat-autocomplete-panel.mat-autocomplete-visible .mat-option.xpo-Option--twoLines .mat-option-text .xpo-Select-twoLinesContainer span {
  overflow: hidden;
  text-overflow: ellipsis;
}
.mat-autocomplete-panel.mat-autocomplete-visible .mat-option.xpo-Option-hideDivider:not(:last-of-type) {
  border-bottom: none;
}
.mat-autocomplete-panel.mat-autocomplete-visible .mat-option .mat-icon {
  margin-right: 0;
}
.mat-autocomplete-panel.mat-autocomplete-visible .mat-option .xpo-icon {
  margin-right: 4px;
  vertical-align: middle;
}
.mat-autocomplete-panel.mat-autocomplete-visible .xpo-Select-twoColumnsContainer {
  display: grid;
  grid-template-columns: 20% 80%;
}
.mat-autocomplete-panel.mat-autocomplete-visible .xpo-Select-twoColumnsContainer span {
  flex: none;
  overflow: hidden;
  text-overflow: ellipsis;
}
.mat-autocomplete-panel.mat-autocomplete-visible .xpo-Select-twoColumnsContainer span:first-of-type {
  margin-right: 4px;
}
.mat-autocomplete-panel.mat-autocomplete-visible .xpo-Select-twoColumnsContainer span:last-of-type {
  margin-left: 4px;
}
.mat-autocomplete-panel.mat-autocomplete-visible .xpo-Select-threeColumnsContainer {
  display: grid;
  grid-template-columns: 33% 34% 33%;
}
.mat-autocomplete-panel.mat-autocomplete-visible .xpo-Select-threeColumnsContainer span {
  margin: 0 4px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.mat-autocomplete-panel.mat-autocomplete-visible .xpo-Select-threeColumnsContainer span:first-of-type {
  margin-left: 0;
}
.mat-autocomplete-panel.mat-autocomplete-visible .xpo-Select-threeColumnsContainer span:last-of-type {
  margin-right: 0;
}

.mat-icon.mat-badge-above:not(.xpo-Badge--important) {
  align-items: center;
  display: inline-flex;
  font-size: 20px;
  height: auto;
  width: auto;
}
.mat-icon.mat-badge-medium {
  color: #4a4a4a;
}
.mat-icon.mat-badge-medium .mat-badge-content {
  align-items: center;
  background-color: #4a4a4a;
  border: 1px solid #ffffff;
  border-radius: 2px;
  display: flex;
  font-size: 10px;
  font-weight: 600;
  height: 16px;
  justify-content: center;
  line-height: 16px;
  padding: 2px 3px;
  width: auto;
}
.mat-icon.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -9px;
}
.mat-icon.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -15px;
}
.mat-icon.mat-badge-medium.xpo-Badge--important.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-icon.mat-badge-medium.xpo-Badge--important.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -28px;
}
.mat-icon.mat-badge-medium.xpo-Badge--important .mat-badge-content {
  background-color: #cc0000;
  border-radius: 10px;
  font-weight: 600;
  height: auto;
  line-height: 10px;
  padding: 4px 8px;
  width: auto;
}
.mat-icon.mat-badge-medium.xpo-BadgeDot .mat-badge-content {
  border: none;
  border-radius: 10px;
  height: 10px;
  width: 10px;
}
.mat-icon.mat-badge-medium.xpo-BadgeDot.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
.mat-icon.mat-badge-medium.xpo-BadgeDot.mat-badge-above .mat-badge-content {
  top: -1px;
}
.mat-icon.mat-badge-medium.xpo-BadgeDot--warning .mat-badge-content {
  background-color: #fbc02d;
}
.mat-icon.mat-badge-medium.xpo-BadgeDot--success .mat-badge-content {
  background-color: #4caf50;
}
.mat-icon.mat-badge-medium.xpo-BadgeDot--info .mat-badge-content {
  background-color: #0091ea;
}
.mat-icon.mat-badge-medium.xpo-BadgeDot--info .mat-badge-content {
  background-color: #0091ea;
}
.mat-icon.mat-badge-medium.xpo-BadgeDot--important .mat-badge-content {
  background-color: #d32f2f;
}

.mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}

.mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}

[dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}

.mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}

.mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}

mat-button-toggle-group.mat-button-toggle-group.mat-button-toggle-standalone.mat-button-toggle-appearance-standard, mat-button-toggle-group.mat-button-toggle-group.mat-button-toggle-group-appearance-standard,
mat-button-toggle.mat-button-toggle-standalone.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
mat-button-toggle.mat-button-toggle-standalone.mat-button-toggle-group-appearance-standard {
  border-radius: 2px;
}
.mat-button-toggle-standalone:not(:last-child) {
  margin-right: 2px;
}

.mat-button-toggle.mat-button-toggle-standalone,
.mat-button-toggle {
  background-color: #ffffff;
  border-color: #1976d2;
  border-style: solid;
  color: #1976d2;
  font-weight: 500;
  transition: color 200ms ease, background-color 200ms ease;
}
.mat-button-toggle.mat-button-toggle-standalone:hover, .mat-button-toggle.mat-button-toggle-standalone:focus,
.mat-button-toggle:hover,
.mat-button-toggle:focus {
  background-color: #e4effa;
}
.mat-button-toggle.mat-button-toggle-standalone:active,
.mat-button-toggle:active {
  background-color: #1976d2;
  color: #ffffff;
}
.mat-button-toggle.mat-button-toggle-standalone.mat-button-toggle-checked,
.mat-button-toggle.mat-button-toggle-checked {
  background-color: #1976d2;
  color: #ffffff;
}
.mat-button-toggle.mat-button-toggle-standalone.mat-button-toggle-disabled,
.mat-button-toggle.mat-button-toggle-disabled {
  background-color: #f6f6f6;
  color: #bdbdbd;
  outline: none;
}
.mat-button-toggle.mat-button-toggle-standalone.mat-button-toggle-disabled .mat-button-toggle-button:hover,
.mat-button-toggle.mat-button-toggle-disabled .mat-button-toggle-button:hover {
  cursor: not-allowed;
}

mat-button-toggle-group.mat-button-toggle-group.mat-button-toggle-standalone.mat-button-toggle-appearance-standard .mat-button-toggle-label-content, mat-button-toggle-group.mat-button-toggle-group.mat-button-toggle-group-appearance-standard .mat-button-toggle-label-content,
mat-button-toggle.mat-button-toggle-standalone.mat-button-toggle-standalone.mat-button-toggle-appearance-standard .mat-button-toggle-label-content,
mat-button-toggle.mat-button-toggle-standalone.mat-button-toggle-group-appearance-standard .mat-button-toggle-label-content {
  display: flex;
  line-height: inherit;
  padding: 4px 20px;
}
mat-button-toggle-group.mat-button-toggle-group.mat-button-toggle-standalone.mat-button-toggle-appearance-standard .mat-button-toggle-label-content > .mat-icon, mat-button-toggle-group.mat-button-toggle-group.mat-button-toggle-group-appearance-standard .mat-button-toggle-label-content > .mat-icon,
mat-button-toggle.mat-button-toggle-standalone.mat-button-toggle-standalone.mat-button-toggle-appearance-standard .mat-button-toggle-label-content > .mat-icon,
mat-button-toggle.mat-button-toggle-standalone.mat-button-toggle-group-appearance-standard .mat-button-toggle-label-content > .mat-icon {
  width: 20px;
  height: 20px;
  font-size: 20px;
}
mat-button-toggle-group.mat-button-toggle-group.mat-button-toggle-standalone.mat-button-toggle-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle, mat-button-toggle-group.mat-button-toggle-group.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle,
mat-button-toggle.mat-button-toggle-standalone.mat-button-toggle-standalone.mat-button-toggle-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle,
mat-button-toggle.mat-button-toggle-standalone.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-top-color: #1976d2;
  border-top-style: solid;
}

.mat-button-toggle-standalone.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  border-color: #bdbdbd;
}

.mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-checkmark {
  fill: #fafafa;
}

.mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}

.mat-checkbox-mixedmark {
  background-color: #fafafa;
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #0868ac;
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #4a4a4a;
}
.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #d50000;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-checkbox .mat-ripple-element {
  background-color: black;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #0868ac;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #4a4a4a;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #d50000;
}

.mat-checkbox-background {
  transition: none !important;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-inner-container {
  border: 1px solid #000000;
  border-radius: 2px;
}
.mat-checkbox-indeterminate.mat-accent.mat-checkbox-disabled .mat-checkbox-inner-container {
  border: 1px solid #bdbdbd;
}
.mat-checkbox-indeterminate.mat-accent.mat-checkbox-disabled .mat-checkbox-inner-container .mat-checkbox-frame {
  border: 1px solid transparent;
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background: #000000;
  border: 2px solid #000000;
  height: 8px;
  margin: 3px 3px;
  padding: 1px 2px 3px 1px;
  width: 8px;
}

.mat-checkbox {
  border: 1px solid transparent;
  display: inline-flex;
  margin: 3px 4px;
  padding: 0 4px;
}
.mat-checkbox.cdk-keyboard-focused {
  border: 1px dashed #d8d8d8;
}
.mat-checkbox.xpo-CheckboxGroup-selectAll {
  margin: 3px 8px;
}
.mat-checkbox .mat-checkbox-layout {
  align-items: center;
}
.mat-checkbox.mat-checkbox-label-before .mat-checkbox-inner-container {
  margin-left: 4px;
}
.mat-checkbox .mat-checkbox-inner-container {
  align-self: flex-start;
  margin: 4px 4px 0 0;
}
.mat-checkbox-ripple {
  display: none;
}
.mat-checkbox-background .mat-checkbox-checkmark-path {
  stroke-width: 3.13333px;
}
.mat-checkbox .mat-checkbox-frame {
  border: none;
}
.mat-checkbox.mat-checkbox-disabled {
  cursor: not-allowed;
}
.mat-checkbox.mat-checkbox-disabled .mat-checkbox-label {
  color: #bdbdbd;
}
.mat-checkbox.mat-checkbox-disabled .mat-checkbox-mixedmark {
  background-color: #bdbdbd;
}
.mat-checkbox.mat-checkbox-disabled .mat-checkbox-checkmark {
  fill: #f6f6f6;
}
.mat-checkbox.mat-checkbox-disabled .mat-checkbox-background {
  background-color: #f6f6f6;
  border: 1px solid #bdbdbd;
}
.mat-checkbox-checked .mat-checkbox.mat-checkbox-disabled .mat-checkbox-background {
  background-color: #bdbdbd;
}
.mat-checkbox.mat-checkbox-disabled .mat-checkbox-background .mat-checkbox-checkmark-path {
  stroke: #ffffff !important;
}
.mat-checkbox.mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #d8d8d8;
}
.mat-checkbox.mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-mixedmark {
  background-color: #f6f6f6;
}
.mat-checkbox.mat-checkbox-disabled .mat-checkbox-frame {
  border-color: #d8d8d8;
}
.mat-checkbox.mat-checkbox-checked .mat-checkbox-background {
  background-color: #000000;
}
.mat-checkbox.mat-checkbox-checked.mat-checkbox-disabled .mat-checkbox-background {
  background-color: #bdbdbd;
}
.mat-checkbox:not(.mat-checkbox-disabled) .mat-checkbox-inner-container:hover {
  background-color: transparent;
}
.mat-checkbox:not(.mat-checkbox-indeterminate) .mat-checkbox-frame {
  border: 1px solid #d8d8d8;
}
.mat-checkbox.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #000000;
}

.mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.mat-chip.mat-standard-chip::after {
  background: black;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #0868ac;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #d50000;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #4a4a4a;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

mat-chip.mat-chip {
  font-weight: 500;
  font-size: 1rem;
  line-height: 24px;
  min-height: 24px;
}
mat-chip.mat-chip .mat-icon {
  height: 1rem;
  width: 1rem;
}
mat-chip.mat-chip.xpo-Chip--small {
  font-size: 0.875rem;
  line-height: 16px;
  min-height: 16px;
}
mat-chip.mat-chip.xpo-Chip--small .mat-icon {
  height: 0.875rem;
  width: 0.875rem;
}
mat-chip.mat-chip.mat-standard-chip {
  background-color: #d8d8d8;
  border-radius: 16px;
  padding-left: 8px;
  padding-right: 8px;
}
mat-chip.mat-chip.mat-standard-chip:not(.mat-chip-disabled):focus, mat-chip.mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: none;
}
mat-chip.mat-chip.mat-standard-chip::after {
  opacity: 0;
}
mat-chip.mat-chip.mat-standard-chip .mat-icon {
  font-size: inherit;
  margin-right: 4px;
}
mat-chip.mat-chip.mat-standard-chip .mat-icon.mat-chip-trailing-icon {
  height: auto;
  margin-left: 4px;
  margin-right: 0;
  width: auto;
}
mat-chip.mat-chip.xpo-Chip {
  display: flex;
  justify-content: center;
}
mat-chip.mat-chip.xpo-Chip--unread, mat-chip.mat-chip.xpo-Chip--critical {
  background: #d50000;
  color: #ffffff;
}
mat-chip.mat-chip.xpo-Chip--high {
  background: #ff9957;
}
mat-chip.mat-chip.xpo-Chip--medium {
  background: #ffba00;
}
mat-chip.mat-chip.xpo-Chip--green {
  background-color: #11a63b;
  color: #ffffff;
}
mat-chip.mat-chip.xpo-Chip--orange {
  background-color: #ff9957;
}
mat-chip.mat-chip.xpo-Chip--red {
  background-color: #d50000;
  color: #ffffff;
}
mat-chip.mat-chip.xpo-Chip--yellow {
  background-color: #ffba00;
}
mat-chip.mat-chip.xpo-BadgeChip.mat-chip {
  font-size: 10px;
  font-weight: 600;
  min-height: 20px;
}
mat-chip.mat-chip.xpo-BadgeChip--negative.mat-standard-chip.mat-chip {
  background-color: #fceeeb;
  border: 1px solid #fceeeb;
  color: #dd2c00;
}
mat-chip.mat-chip.xpo-BadgeChip--positive.mat-standard-chip.mat-chip {
  background-color: #f1f9f1;
  border: 1px solid #f1f9f1;
  color: #4caf50;
}

.mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

mat-dialog-container.mat-dialog-container {
  border-radius: 0;
}
mat-dialog-container.mat-dialog-container .xpo-Dialog-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
mat-dialog-container.mat-dialog-container [mat-dialog-title].mat-dialog-title {
  color: #000000;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  font-size: 1.714rem;
  line-height: 28px;
  font-weight: 500;
  justify-content: space-between;
  margin: 0 24px;
  padding-bottom: 12px;
  padding-top: 24px;
}
mat-dialog-container.mat-dialog-container [mat-dialog-title].mat-dialog-title:not(.xpo-ConfirmDialog-header) {
  border-bottom: 1px solid #d8d8d8;
}
mat-dialog-container.mat-dialog-container [mat-dialog-content].mat-dialog-content,
mat-dialog-container.mat-dialog-container mat-dialog-content.mat-dialog-content {
  color: #333333;
  font-size: 1.143rem;
  line-height: 24px;
  margin: 0;
  margin-bottom: auto;
  overflow: auto;
  padding: 12px 24px 24px;
}
mat-dialog-container.mat-dialog-container [mat-dialog-content].mat-dialog-content p:not(:last-of-type),
mat-dialog-container.mat-dialog-container mat-dialog-content.mat-dialog-content p:not(:last-of-type) {
  margin-bottom: 24px;
}
mat-dialog-container.mat-dialog-container [mat-dialog-actions].mat-dialog-actions,
mat-dialog-container.mat-dialog-container mat-dialog-actions.mat-dialog-actions {
  border-top: 1px solid #d8d8d8;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin: 0 24px;
  min-height: auto;
  padding: 24px 0 28px;
}
mat-dialog-container.mat-dialog-container [mat-dialog-actions].mat-dialog-actions .xpo-ButtonGroup > .mat-button-base,
mat-dialog-container.mat-dialog-container mat-dialog-actions.mat-dialog-actions .xpo-ButtonGroup > .mat-button-base {
  margin-left: 0;
}

.mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}

.mat-expansion-panel-header {
  height: 48px;
}
.mat-expansion-panel-header.mat-expanded {
  height: 64px;
}

.mat-accordion > .mat-expansion-panel,
.mat-expansion-panel {
  margin: 0.1px;
}
.mat-accordion > .mat-expansion-panel, .mat-accordion > .mat-expansion-panel:not([class*=mat-elevation-z]),
.mat-expansion-panel,
.mat-expansion-panel:not([class*=mat-elevation-z]) {
  border-radius: 0;
  box-shadow: none;
}
.mat-accordion > .mat-expansion-panel .mat-expansion-panel-header,
.mat-expansion-panel .mat-expansion-panel-header {
  padding: 8px;
}
.mat-accordion > .mat-expansion-panel .mat-expansion-panel-header .mat-content::before,
.mat-expansion-panel .mat-expansion-panel-header .mat-content::before {
  font-family: "Material Icons";
  font-size: 24px;
}
.mat-accordion > .mat-expansion-panel .mat-expansion-panel-header.mat-expanded > .mat-content::before,
.mat-expansion-panel .mat-expansion-panel-header.mat-expanded > .mat-content::before {
  content: "remove";
}
.mat-accordion > .mat-expansion-panel .mat-expansion-panel-header:not(.mat-expanded) > .mat-content::before,
.mat-expansion-panel .mat-expansion-panel-header:not(.mat-expanded) > .mat-content::before {
  content: "add";
}
.mat-accordion > .mat-expansion-panel .mat-icon,
.mat-expansion-panel .mat-icon {
  font-size: 20px;
}
.mat-accordion > .mat-expansion-panel .mat-expansion-panel-body,
.mat-expansion-panel .mat-expansion-panel-body {
  padding: 8px 39px 8px;
  padding-bottom: 8px;
}
.mat-accordion > .mat-expansion-panel .mat-expansion-panel-body p,
.mat-expansion-panel .mat-expansion-panel-body p {
  font-size: 14px;
}
.mat-accordion > .mat-expansion-panel .mat-expansion-panel-content .mat-cell > .mat-icon,
.mat-expansion-panel .mat-expansion-panel-content .mat-cell > .mat-icon {
  margin-right: 8px;
}
.mat-accordion > .mat-expansion-panel .mat-content,
.mat-expansion-panel .mat-content {
  align-items: center;
  line-height: 24px;
}
.mat-accordion > .mat-expansion-panel .mat-content .mat-expansion-panel-header-title,
.mat-expansion-panel .mat-content .mat-expansion-panel-header-title {
  margin-right: 0;
  padding: 8px 10px;
  flex-basis: auto;
}
.mat-accordion > .mat-expansion-panel .mat-content .mat-expansion-panel-header-description,
.mat-expansion-panel .mat-content .mat-expansion-panel-header-description {
  flex-basis: auto;
}
.mat-accordion > .mat-expansion-panel:not(.xpo-ExpansionPanelDark--wrap),
.mat-expansion-panel:not(.xpo-ExpansionPanelDark--wrap) {
  border-bottom: 1px solid #d8d8d8;
}
.mat-accordion > .mat-expansion-panel:not(.xpo-ExpansionPanelDark--wrap) mat-expansion-panel-header.mat-expansion-panel-header,
.mat-expansion-panel:not(.xpo-ExpansionPanelDark--wrap) mat-expansion-panel-header.mat-expansion-panel-header {
  font-size: 1.143rem;
}
.mat-accordion > .mat-expansion-panel:not(.xpo-ExpansionPanelDark--wrap) mat-expansion-panel-header.mat-expansion-panel-header:not([aria-disabled=true]) .mat-expansion-panel-header-title,
.mat-expansion-panel:not(.xpo-ExpansionPanelDark--wrap) mat-expansion-panel-header.mat-expansion-panel-header:not([aria-disabled=true]) .mat-expansion-panel-header-title {
  color: #000000;
}
.mat-accordion > .mat-expansion-panel:not(.xpo-ExpansionPanelDark--wrap) mat-expansion-panel-header.mat-expansion-panel-header:not([aria-disabled=true]) .mat-content::before,
.mat-expansion-panel:not(.xpo-ExpansionPanelDark--wrap) mat-expansion-panel-header.mat-expansion-panel-header:not([aria-disabled=true]) .mat-content::before {
  color: #424242;
}
.mat-accordion > .mat-expansion-panel:not(.xpo-ExpansionPanelDark--wrap) mat-expansion-panel-header.mat-expansion-panel-header[aria-disabled=true],
.mat-expansion-panel:not(.xpo-ExpansionPanelDark--wrap) mat-expansion-panel-header.mat-expansion-panel-header[aria-disabled=true] {
  color: #d8d8d8;
}
.mat-accordion > .mat-expansion-panel.xpo-ExpansionPanelDark--wrap,
.mat-expansion-panel.xpo-ExpansionPanelDark--wrap {
  margin-bottom: 8px;
}
.mat-accordion > .mat-expansion-panel.xpo-ExpansionPanelDark--wrap:not(.mat-expanded),
.mat-expansion-panel.xpo-ExpansionPanelDark--wrap:not(.mat-expanded) {
  border: 1px solid #d8d8d8;
}
.mat-accordion > .mat-expansion-panel.xpo-ExpansionPanelDark--wrap.mat-expanded .mat-expansion-panel-content,
.mat-expansion-panel.xpo-ExpansionPanelDark--wrap.mat-expanded .mat-expansion-panel-content {
  border: 1px solid #d8d8d8;
  border-top: none;
}
.mat-accordion > .mat-expansion-panel.xpo-ExpansionPanelDark--wrap .mat-expansion-panel-header,
.mat-expansion-panel.xpo-ExpansionPanelDark--wrap .mat-expansion-panel-header {
  padding: 0;
}
.mat-accordion > .mat-expansion-panel.xpo-ExpansionPanelDark--wrap .mat-expansion-panel-header[aria-disabled=true] .mat-content::before,
.mat-expansion-panel.xpo-ExpansionPanelDark--wrap .mat-expansion-panel-header[aria-disabled=true] .mat-content::before {
  color: #f6f6f6 !important;
}
.mat-accordion > .mat-expansion-panel.xpo-ExpansionPanelDark--wrap .mat-expansion-panel-header[aria-disabled=true] .mat-content .mat-expansion-panel-header-title,
.mat-expansion-panel.xpo-ExpansionPanelDark--wrap .mat-expansion-panel-header[aria-disabled=true] .mat-content .mat-expansion-panel-header-title {
  color: #d8d8d8;
}
.mat-accordion > .mat-expansion-panel.xpo-ExpansionPanelDark--wrap .mat-expansion-panel-header.mat-expanded .mat-content::before,
.mat-expansion-panel.xpo-ExpansionPanelDark--wrap .mat-expansion-panel-header.mat-expanded .mat-content::before {
  background-color: #333333;
  color: #ffffff;
  margin-right: 0;
  padding: 8px;
}
.mat-accordion > .mat-expansion-panel.xpo-ExpansionPanelDark--wrap .mat-expansion-panel-header.mat-expanded .mat-content,
.mat-expansion-panel.xpo-ExpansionPanelDark--wrap .mat-expansion-panel-header.mat-expanded .mat-content {
  background-color: #4a4a4a;
  display: inline-flex;
}
.mat-accordion > .mat-expansion-panel.xpo-ExpansionPanelDark--wrap .mat-expansion-panel-header.mat-expanded .mat-content > .mat-expansion-panel-header-title,
.mat-accordion > .mat-expansion-panel.xpo-ExpansionPanelDark--wrap .mat-expansion-panel-header.mat-expanded .mat-content .mat-expansion-panel-header-description,
.mat-expansion-panel.xpo-ExpansionPanelDark--wrap .mat-expansion-panel-header.mat-expanded .mat-content > .mat-expansion-panel-header-title,
.mat-expansion-panel.xpo-ExpansionPanelDark--wrap .mat-expansion-panel-header.mat-expanded .mat-content .mat-expansion-panel-header-description {
  color: #ffffff;
}
.mat-accordion > .mat-expansion-panel.xpo-ExpansionPanelDark--wrap .mat-expansion-panel-header:not(.mat-expanded) .mat-content,
.mat-expansion-panel.xpo-ExpansionPanelDark--wrap .mat-expansion-panel-header:not(.mat-expanded) .mat-content {
  background-color: #f6f6f6;
  color: #171717;
  display: inline-flex;
}
.mat-accordion > .mat-expansion-panel.xpo-ExpansionPanelDark--wrap .mat-expansion-panel-header:not(.mat-expanded) .mat-content::before,
.mat-expansion-panel.xpo-ExpansionPanelDark--wrap .mat-expansion-panel-header:not(.mat-expanded) .mat-content::before {
  background-color: #d8d8d8;
  color: #333333;
  margin-right: 0;
  padding: 8px;
}
.mat-accordion > .mat-expansion-panel mat-expansion-panel-header.mat-expansion-panel-header ::before,
.mat-accordion > .mat-expansion-panel mat-expansion-panel-header.mat-expansion-panel-header .mat-icon,
.mat-accordion > .mat-expansion-panel mat-expansion-panel-header.mat-expansion-panel-header .mat-expansion-panel-header-title,
.mat-accordion > .mat-expansion-panel mat-expansion-panel-header.mat-expansion-panel-header .mat-expansion-panel-header-description,
.mat-expansion-panel mat-expansion-panel-header.mat-expansion-panel-header ::before,
.mat-expansion-panel mat-expansion-panel-header.mat-expansion-panel-header .mat-icon,
.mat-expansion-panel mat-expansion-panel-header.mat-expansion-panel-header .mat-expansion-panel-header-title,
.mat-expansion-panel mat-expansion-panel-header.mat-expansion-panel-header .mat-expansion-panel-header-description {
  align-items: center;
  display: flex;
}
.mat-accordion > .mat-expansion-panel mat-expansion-panel-header.mat-expansion-panel-header ::before,
.mat-expansion-panel mat-expansion-panel-header.mat-expansion-panel-header ::before {
  margin-right: 8px;
}
.mat-accordion > .mat-expansion-panel mat-expansion-panel-header.mat-expansion-panel-header .mat-expansion-panel-header-title,
.mat-expansion-panel mat-expansion-panel-header.mat-expansion-panel-header .mat-expansion-panel-header-title {
  font-size: 1.143rem;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 1.143;
  flex-grow: 0;
  line-height: 24px;
}
.mat-accordion > .mat-expansion-panel mat-expansion-panel-header.mat-expansion-panel-header .mat-expansion-panel-header-description,
.mat-expansion-panel mat-expansion-panel-header.mat-expansion-panel-header .mat-expansion-panel-header-description {
  font-size: 0.875rem;
  line-height: 16px;
  flex-grow: 0;
}
.mat-accordion > .mat-expansion-panel mat-expansion-panel-header.mat-expansion-panel-header .xpo-StatusIndicator:not(:first-of-type),
.mat-expansion-panel mat-expansion-panel-header.mat-expansion-panel-header .xpo-StatusIndicator:not(:first-of-type) {
  margin-left: 8px;
}
.mat-accordion > .mat-expansion-panel mat-expansion-panel-header.mat-expansion-panel-header .xpo-StatusIndicator-tag,
.mat-expansion-panel mat-expansion-panel-header.mat-expansion-panel-header .xpo-StatusIndicator-tag {
  font-size: 10px;
}
.mat-accordion > .mat-expansion-panel.mat-expanded.mat-expansion-panel-spacing,
.mat-expansion-panel.mat-expanded.mat-expansion-panel-spacing {
  margin: 0;
}
.mat-accordion > .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled=true]):hover,
.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled=true]):hover {
  background: none;
}

.mat-accordion mat-expansion-panel.mat-expansion-panel:last-of-type {
  border-radius: 0;
}

.mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}

.mat-hint {
  color: rgba(0, 0, 0, 0.6);
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #0868ac;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #4a4a4a;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #d50000;
}

.mat-focused .mat-form-field-required-marker {
  color: #4a4a4a;
}

.mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #0868ac;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #4a4a4a;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #d50000;
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #0868ac;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #4a4a4a;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #d50000;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #d50000;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #d50000;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #d50000;
}

.mat-error {
  color: #d50000;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #0868ac;
}
.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #4a4a4a;
}
.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #d50000;
}
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #d50000;
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}

mat-form-field.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float.mat-form-field-has-label .mat-form-field-label {
  transform: none;
  top: 0;
  font-size: 14px;
  color: #4a4a4a !important;
  font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
  font-weight: normal;
  letter-spacing: normal;
  overflow: visible;
  line-height: 16px;
}
mat-form-field.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float.mat-form-field-has-label .mat-form-field-label mat-label {
  align-items: center;
  display: inline-flex;
}
mat-form-field.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float.mat-form-field-has-label .mat-form-field-label mat-label .mat-icon {
  padding-left: 8px;
}
mat-form-field.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float.mat-form-field-has-label .mat-form-field-label-wrapper {
  left: 0;
  top: -24px;
}

mat-form-field.mat-form-field-type-mat-input {
  margin-bottom: 16px;
  margin-top: 24px;
}
mat-form-field.mat-form-field-type-mat-input.mat-form-field-appearance-standard .mat-form-field-flex {
  align-items: center;
  padding-top: 0;
}
mat-form-field.mat-form-field-type-mat-input.mat-form-field-appearance-legacy.mat-form-field-hide-placeholder .mat-form-field-label {
  font-size: 1rem;
  letter-spacing: normal;
  opacity: 0.5;
}
mat-form-field.mat-form-field-type-mat-input.mat-form-field-appearance-legacy.mat-form-field-hide-placeholder .mat-form-field-label-wrapper {
  left: 9px;
  overflow: visible;
  top: -12px;
}
mat-form-field.mat-form-field-type-mat-input.mat-form-field-appearance-legacy.mat-form-field-hide-placeholder.mat-form-field-should-float .mat-form-field-label {
  font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
  font-size: 1.285rem;
  font-weight: normal;
  letter-spacing: inherit;
  opacity: 1;
}
mat-form-field.mat-form-field-type-mat-input.mat-form-field-appearance-legacy.mat-form-field-invalid .mat-form-field-flex, mat-form-field.mat-form-field-type-mat-input.mat-form-field-appearance-standard.mat-form-field-invalid .mat-form-field-flex {
  border-color: #d50000;
}
mat-form-field.mat-form-field-type-mat-input.mat-form-field-appearance-legacy .mat-form-field-wrapper, mat-form-field.mat-form-field-type-mat-input.mat-form-field-appearance-standard .mat-form-field-wrapper {
  height: unset;
  line-height: 16px;
  padding: 0;
  top: 0;
}
mat-form-field.mat-form-field-type-mat-input.mat-form-field-appearance-legacy.ngx-FormField--inline,
mat-form-field.mat-form-field-type-mat-input.mat-form-field-appearance-legacy.ngx-FormField--inline .mat-form-field-wrapper, mat-form-field.mat-form-field-type-mat-input.mat-form-field-appearance-standard.ngx-FormField--inline,
mat-form-field.mat-form-field-type-mat-input.mat-form-field-appearance-standard.ngx-FormField--inline .mat-form-field-wrapper {
  margin-bottom: 0;
  margin-top: 0;
}
mat-form-field.mat-form-field-type-mat-input.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper, mat-form-field.mat-form-field-type-mat-input.mat-form-field-appearance-standard .mat-form-field-subscript-wrapper {
  margin: 0;
  position: relative;
}
mat-form-field.mat-form-field-type-mat-input.mat-form-field-appearance-legacy .mat-hint, mat-form-field.mat-form-field-type-mat-input.mat-form-field-appearance-standard .mat-hint {
  font-size: 0.875rem;
  margin-top: 8px;
}
mat-form-field.mat-form-field-type-mat-input.mat-form-field-appearance-legacy .mat-error, mat-form-field.mat-form-field-type-mat-input.mat-form-field-appearance-standard .mat-error {
  display: flex;
  font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 16px;
  margin-top: 8px;
}
mat-form-field.mat-form-field-type-mat-input.mat-form-field-appearance-legacy .mat-error .mat-icon, mat-form-field.mat-form-field-type-mat-input.mat-form-field-appearance-standard .mat-error .mat-icon {
  display: inline-flex;
  font-size: 20px;
  height: auto;
  line-height: 16px;
  margin-right: 4px;
  width: auto;
}
mat-form-field.mat-form-field-type-mat-input.mat-form-field-appearance-legacy.ngx-FormField--borderless .mat-form-field-flex, mat-form-field.mat-form-field-type-mat-input.mat-form-field-appearance-standard.ngx-FormField--borderless .mat-form-field-flex {
  border: none;
  box-shadow: unset;
}
mat-form-field.mat-form-field-type-mat-input.mat-form-field-appearance-legacy .mat-form-field-underline, mat-form-field.mat-form-field-type-mat-input.mat-form-field-appearance-standard .mat-form-field-underline {
  display: none;
}
mat-form-field.mat-form-field-type-mat-input:not(.mat-form-field-has-label) {
  margin-top: 0;
}
mat-form-field.mat-form-field-type-mat-input.mat-focused .mat-form-field-flex {
  border: 1px solid #000000;
}
mat-form-field.mat-form-field-type-mat-input.mat-focused .mat-form-field-wrapper {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
mat-form-field.mat-form-field-type-mat-input .mat-form-field-flex {
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  border-radius: 2px;
  display: flex;
}
mat-form-field.mat-form-field-type-mat-input .mat-form-field-flex .mat-form-field-prefix,
mat-form-field.mat-form-field-type-mat-input .mat-form-field-flex .mat-form-field-suffix {
  display: flex;
}
mat-form-field.mat-form-field-type-mat-input .mat-form-field-flex .mat-form-field-prefix .mat-icon,
mat-form-field.mat-form-field-type-mat-input .mat-form-field-flex .mat-form-field-suffix .mat-icon {
  display: inline-flex;
  font-size: 20px;
  height: 16px;
  justify-content: center;
  line-height: 16px;
  width: 16px;
}
mat-form-field.mat-form-field-type-mat-input .mat-form-field-flex .mat-form-field-prefix .xpo-BusyLoader,
mat-form-field.mat-form-field-type-mat-input .mat-form-field-flex .mat-form-field-suffix .xpo-BusyLoader {
  width: 24px;
}
mat-form-field.mat-form-field-type-mat-input .mat-form-field-flex .mat-form-field-prefix {
  margin-left: 6px;
}
mat-form-field.mat-form-field-type-mat-input .mat-form-field-flex .mat-form-field-suffix {
  margin-right: 6px;
}
mat-form-field.mat-form-field-type-mat-input .mat-form-field-flex .mat-form-field-infix {
  align-items: center;
  border: 0;
  display: flex;
  padding: 0;
}
mat-form-field.mat-form-field-type-mat-input .mat-form-field-flex .mat-form-field-infix .mat-icon + .mat-input-element {
  padding-left: 0;
}
mat-form-field.mat-form-field-type-mat-input .mat-form-field-flex .mat-form-field-infix .mat-input-element {
  color: #000000;
  font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
  font-size: 1rem;
  margin: 0;
  padding: 6px 8px;
  resize: none;
}
mat-form-field.mat-form-field-type-mat-input .mat-form-field-flex .mat-form-field-infix .mat-input-element:disabled {
  background-color: #f3f3f3;
  color: #000000;
  font-style: italic;
  font-weight: 400;
}
mat-form-field.mat-form-field-type-mat-input .mat-form-field-label {
  color: #333333 !important;
  font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
  font-size: 1.428rem;
  font-weight: normal;
  letter-spacing: normal;
  overflow: visible;
}
mat-form-field.mat-form-field-type-mat-input .mat-form-field-label mat-label {
  align-items: center;
  display: inline-flex;
}
mat-form-field.mat-form-field-type-mat-input .mat-form-field-label mat-label .mat-icon {
  padding-left: 8px;
}
mat-form-field .xpo-Label--wrap .mat-checkbox-layout,
mat-form-field .xpo-Label--wrap .mat-radio-label {
  white-space: unset;
}

.xpo-Form--inline {
  align-items: flex-end;
  display: inline-flex;
  flex-wrap: wrap;
  width: 100%;
}
.xpo-Form--inline .mat-button-base,
.xpo-Form--inline .mat-button-toggle-group,
.xpo-Form--inline .mat-form-field,
.xpo-Form--inline .mat-form-field-type-mat-input:not(.mat-form-field-has-label) {
  margin-bottom: 16px;
  margin-right: 8px;
  width: auto;
}
.xpo-Form--inline > .mat-button-toggle {
  margin-bottom: 16px;
}
.xpo-Form--inline > .mat-button-toggle:last-of-type {
  margin-right: 8px;
}

.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-input-element {
  caret-color: #0868ac;
}
.mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-form-field.mat-accent .mat-input-element {
  caret-color: #4a4a4a;
}

.mat-form-field.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  caret-color: #d50000;
}

.mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #d50000;
}

.mat-form-field-disabled .mat-form-field-prefix,
.mat-form-field-disabled .mat-form-field-suffix {
  color: rgba(0, 0, 0, 0.38);
}

input.mat-input-element {
  caret-color: #4a4a4a !important;
  margin-top: 0.1375em;
}
input.mat-input-element:disabled {
  cursor: not-allowed;
}

.xpo-Link {
  align-items: center;
  color: #1976d2;
  cursor: pointer;
  display: inline-flex;
  font-weight: 500;
  gap: 5px;
}
.xpo-Link:hover, .xpo-Link:focus, .xpo-Link:active {
  text-decoration: underline;
}
.xpo-Link:hover {
  color: #2196f3;
  outline: 0;
}
.xpo-Link:active, .xpo-Link:focus {
  color: #0d47a1;
  outline: none;
}
.xpo-Link[disabled] {
  color: #bdbdbd;
  cursor: not-allowed;
}
.xpo-Link--regular {
  font-weight: 400;
}
.xpo-Link > .mat-icon {
  font-size: 1.428rem;
  height: 20px;
  width: 20px;
  text-decoration: none;
}

.mat-menu-panel {
  background: white;
}
.mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.mat-menu-item[disabled],
.mat-menu-item[disabled] .mat-menu-submenu-icon,
.mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}

.mat-menu-item .mat-icon-no-color,
.mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}

.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}

div.mat-menu-panel {
  border-radius: 0;
  border: 1px solid #d8d8d8;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  margin-top: 4px;
}
div.mat-menu-panel .mat-menu-content:not(:empty) {
  padding-bottom: 0;
  padding-top: 0;
}
div.mat-menu-panel .mat-menu-item {
  align-items: center;
  color: #000000;
  display: flex;
  gap: 8px;
  height: 32px;
  justify-content: flex-start;
  line-height: 1.429rem;
  padding: 6px 8px;
}
div.mat-menu-panel .mat-menu-item .xpo-Icon .mat-icon {
  color: #000000;
  margin-right: 0;
}
div.mat-menu-panel .mat-menu-item .xpo-Icon .mat-icon:hover {
  color: #212121;
}
div.mat-menu-panel .mat-menu-item:disabled {
  color: #bdbdbd;
}
div.mat-menu-panel .mat-menu-item:disabled .xpo-Icon .mat-icon {
  color: #bdbdbd;
}
div.mat-menu-panel .mat-menu-item:hover {
  background: #e4effa;
}
div.mat-menu-panel .mat-menu-item-submenu-trigger::after {
  margin-right: -7px;
}
div.mat-menu-panel a.mat-menu-item,
div.mat-menu-panel a.mat-menu-item:hover,
div.mat-menu-panel a.mat-menu-item:focus {
  text-decoration: none;
}
div.mat-menu-panel .mat-divider {
  color: #d8d8d8;
}
div.mat-menu-panel .mat-divider:last-child {
  display: none;
}

.cdk-overlay-connected-position-bounding-box:not(:nth-of-type(2)) .mat-menu-panel::after {
  display: none;
}

.mat-form-field.mat-form-field-type-xpo-multi-select {
  line-height: 24px;
}
.mat-form-field.mat-form-field-type-xpo-multi-select.mat-form-field-appearance-legacy.mat-form-field-should-float {
  margin-bottom: 16px;
  margin-top: 24px;
}
.mat-form-field.mat-form-field-type-xpo-multi-select.mat-form-field-appearance-legacy.mat-form-field-should-float .mat-form-field-wrapper {
  padding: 0;
}
.mat-form-field.mat-form-field-type-xpo-multi-select.mat-form-field-appearance-legacy.mat-form-field-should-float .mat-form-field-wrapper .mat-form-field-flex {
  cursor: pointer;
}
.mat-form-field.mat-form-field-type-xpo-multi-select.mat-form-field-appearance-legacy.mat-form-field-should-float .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix {
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  border-radius: 2px;
  padding: 2px 8px;
  width: 260px;
}
.mat-form-field.mat-form-field-type-xpo-multi-select.mat-form-field-appearance-legacy.mat-form-field-should-float .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-select-arrow {
  margin-right: 0;
}
.mat-form-field.mat-form-field-type-xpo-multi-select.mat-form-field-appearance-legacy.mat-form-field-should-float .mat-form-field-wrapper .mat-form-field-underline {
  display: none;
}
.mat-form-field.mat-form-field-type-xpo-multi-select.mat-form-field-appearance-legacy.mat-form-field-should-float .mat-form-field-wrapper .mat-form-field-subscript-wrapper {
  margin: 0;
  position: relative;
}
.mat-form-field.mat-form-field-type-xpo-multi-select.mat-form-field-appearance-legacy.mat-form-field-should-float .mat-form-field-wrapper .mat-form-field-subscript-wrapper .mat-error {
  display: flex;
  font-size: 1rem;
  font-weight: 400;
  line-height: 16px;
  margin-top: 8px;
}
.mat-form-field.mat-form-field-type-xpo-multi-select.mat-form-field-appearance-legacy.mat-form-field-should-float .mat-form-field-wrapper .mat-form-field-subscript-wrapper .mat-error .mat-icon {
  display: inline-flex;
  font-size: 20px;
  height: auto;
  line-height: 16px;
  margin-right: 4px;
  width: auto;
}
.mat-form-field.mat-form-field-type-xpo-multi-select.mat-form-field-appearance-legacy.mat-form-field-should-float.mat-focused .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix {
  border-color: #000000;
}
.mat-form-field.mat-form-field-type-xpo-multi-select.mat-form-field-appearance-legacy.mat-form-field-should-float.mat-focused .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-select-arrow {
  color: #4a4a4a;
}
.mat-form-field.mat-form-field-type-xpo-multi-select.mat-form-field-appearance-legacy.mat-form-field-should-float.mat-form-field-disabled .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix {
  background-color: #f6f6f6;
  cursor: not-allowed;
}
.mat-form-field.mat-form-field-type-xpo-multi-select.mat-form-field-appearance-legacy.mat-form-field-should-float.mat-form-field-disabled .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-select-trigger {
  cursor: not-allowed;
}

.mat-select-panel.xpo-Select-panel.xpo-Select-panelMultiple.xpo-MultiSelect-panel {
  border: 1px solid #000000;
  margin-top: 2px;
  margin-bottom: 2px;
}
.mat-select-panel.xpo-Select-panel.xpo-Select-panelMultiple.xpo-MultiSelect-panel .mat-option .mat-option-text .mat-icon {
  margin-right: 6px;
}
.mat-select-panel.xpo-Select-panel.xpo-Select-panelMultiple.xpo-MultiSelect-panel .mat-option.xpo-MultiSelectAllOption .mat-pseudo-checkbox-indeterminate {
  background: #ffffff;
  border: 1px solid #000000;
}
.mat-select-panel.xpo-Select-panel.xpo-Select-panelMultiple.xpo-MultiSelect-panel .mat-option.xpo-MultiSelectAllOption .mat-pseudo-checkbox-indeterminate::after {
  background: #000000;
  border: 2px solid #000000;
  height: 8px;
  left: 3px;
  top: 3px;
  width: 8px;
}

.xpo-MultiSelectTrigger .mat-chip-list .mat-chip-list-wrapper {
  display: inline-flex;
  flex-wrap: nowrap;
  margin: -4px 0;
  gap: 0;
}

.mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-simple-snackbar-action {
  color: #4a4a4a;
}

snack-bar-container.mat-snack-bar-container {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  background: #ffffff;
  border-radius: 2px;
  color: #4a4a4a;
  padding: 0;
  max-height: 40vh;
  overflow: hidden;
  margin: 32px;
  max-width: 80vw;
}
snack-bar-container.mat-snack-bar-container > div {
  height: 100%;
}
snack-bar-container.mat-snack-bar-container > div > div {
  height: 100%;
}

.mat-table {
  background: white;
}

.mat-table thead, .mat-table tbody, .mat-table tfoot,
mat-header-row, mat-row, mat-footer-row,
[mat-header-row], [mat-row], [mat-footer-row],
.mat-table-sticky {
  background: inherit;
}

mat-row, mat-header-row, mat-footer-row,
th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}

.mat-cell, .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}

.mat-table {
  border: 1px solid #d8d8d8;
}
.mat-table tr.mat-header-row,
.mat-table tr.mat-footer-row,
.mat-table tr.mat-row {
  height: 30px;
}
.mat-table mat-header-row.mat-header-row,
.mat-table mat-footer-row.mat-footer-row,
.mat-table mat-row.mat-row {
  min-height: 30px;
}
.mat-table mat-row.mat-row,
.mat-table mat-header-row.mat-header-row,
.mat-table mat-footer-row.mat-footer-row,
.mat-table th.mat-header-cell,
.mat-table td.mat-cell,
.mat-table td.mat-footer-cell {
  border-bottom-color: #d8d8d8;
}
.mat-table mat-row.mat-row:last-child,
.mat-table mat-footer-row.mat-footer-row:last-child,
.mat-table tr.mat-row:last-child > .mat-cell {
  border-bottom-color: transparent;
}
.mat-table .mat-cell .mat-icon {
  align-items: center;
  justify-content: center;
}
.mat-table .mat-cell > .xpo-Icon {
  margin-right: 4px;
}
.mat-table th.mat-header-cell,
.mat-table td.mat-cell,
.mat-table td.mat-footer-cell,
.mat-table mat-cell.mat-cell,
.mat-table mat-header-cell.mat-header-cell,
.mat-table mat-footer-cell.mat-footer-cell {
  font-size: 1rem;
}
.mat-table th.mat-header-cell, .mat-table th.mat-header-cell:first-of-type,
.mat-table td.mat-cell,
.mat-table td.mat-cell:first-of-type,
.mat-table td.mat-footer-cell,
.mat-table td.mat-footer-cell:first-of-type,
.mat-table mat-cell.mat-cell,
.mat-table mat-cell.mat-cell:first-of-type,
.mat-table mat-header-cell.mat-header-cell,
.mat-table mat-header-cell.mat-header-cell:first-of-type,
.mat-table mat-footer-cell.mat-footer-cell,
.mat-table mat-footer-cell.mat-footer-cell:first-of-type {
  padding-left: 8px;
}
.mat-table th.mat-header-cell, .mat-table th.mat-header-cell:last-of-type,
.mat-table td.mat-cell,
.mat-table td.mat-cell:last-of-type,
.mat-table td.mat-footer-cell,
.mat-table td.mat-footer-cell:last-of-type,
.mat-table mat-cell.mat-cell,
.mat-table mat-cell.mat-cell:last-of-type,
.mat-table mat-header-cell.mat-header-cell,
.mat-table mat-header-cell.mat-header-cell:last-of-type,
.mat-table mat-footer-cell.mat-footer-cell,
.mat-table mat-footer-cell.mat-footer-cell:last-of-type {
  padding-right: 8px;
}
.mat-table tr.mat-header-row,
.mat-table mat-header-row.mat-header-row {
  background-color: #f6f6f6;
  box-shadow: 0 4px -2px -1px rgba(0, 0, 0, 0.1);
}
.mat-table tr.mat-header-row .mat-header-cell,
.mat-table mat-header-row.mat-header-row .mat-header-cell {
  color: #4a4a4a;
  font-size: 0.875rem;
  font-weight: 500;
}
.mat-table tr.mat-footer-row,
.mat-table mat-footer-row.mat-footer-row {
  background-color: #e6e6e6;
}
.mat-table tr.mat-footer-row .mat-footer-cell,
.mat-table mat-footer-row.mat-footer-row .mat-footer-cell {
  font-weight: 500;
}
.mat-table .mat-tab-label.mat-tab-label-active {
  color: #000000;
}

.mat-cell .mat-icon {
  align-items: center;
  justify-content: center;
}
.mat-cell > .xpo-Icon {
  margin-right: 4px;
}

.xpo-MatTable--withColumnGroup tr.mat-header-row:first-of-type {
  background-color: #e6e6e6;
}
.xpo-MatTable--withColumnGroup tr.mat-header-row:first-of-type th.mat-header-cell {
  text-align: center;
}
.xpo-MatTable--borderRight {
  border-right: 1px solid #d8d8d8;
}
.xpo-MatTable--borderLeft {
  border-left: 1px solid #d8d8d8;
}
.xpo-MatTable-cell--textAlignRight.mat-header-cell, .xpo-MatTable-cell--textAlignRight.mat-cell, .xpo-MatTable-cell--textAlignRight.mat-footer-cell {
  justify-content: flex-end;
  text-align: right;
}
.xpo-MatTable-cell--textAlignCenter.mat-header-cell, .xpo-MatTable-cell--textAlignCenter.mat-cell, .xpo-MatTable-cell--textAlignCenter.mat-footer-cell {
  justify-content: center;
  text-align: center;
}

.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.mat-tab-group-inverted-header .mat-tab-nav-bar,
.mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}

.mat-tab-label, .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.mat-tab-label.mat-tab-disabled, .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-tab-group[class*=mat-background-] > .mat-tab-header,
.mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}

.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused, .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused,
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused,
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused, .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused, .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused,
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused,
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused {
  background-color: rgba(232, 245, 254, 0.3);
}
.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #0868ac;
}
.mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused, .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused,
.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused,
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused, .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused, .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused,
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused,
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused {
  background-color: rgba(155, 155, 155, 0.3);
}
.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-tab-group.mat-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #4a4a4a;
}
.mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused, .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused,
.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused,
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused, .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused, .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused,
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused,
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused {
  background-color: rgba(255, 34, 34, 0.3);
}
.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-tab-group.mat-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #d50000;
}
.mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused, .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused,
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused,
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused, .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused, .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused,
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused,
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused {
  background-color: rgba(232, 245, 254, 0.3);
}
.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-tab-group.mat-background-primary > .mat-tab-header, .mat-tab-group.mat-background-primary > .mat-tab-link-container, .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #0868ac;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused, .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused,
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused,
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused, .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused, .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused,
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused,
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused {
  background-color: rgba(155, 155, 155, 0.3);
}
.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-tab-group.mat-background-accent > .mat-tab-header, .mat-tab-group.mat-background-accent > .mat-tab-link-container, .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #4a4a4a;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused, .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused,
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused,
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused, .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused, .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused,
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused,
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused {
  background-color: rgba(255, 34, 34, 0.3);
}
.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-tab-group.mat-background-warn > .mat-tab-header, .mat-tab-group.mat-background-warn > .mat-tab-link-container, .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #d50000;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}

.mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #0868ac;
}
.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #0868ac;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #4a4a4a;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #4a4a4a;
}
.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #d50000;
}
.mat-radio-button.mat-warn .mat-radio-inner-circle,
.mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #d50000;
}
.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button .mat-ripple-element {
  background-color: black;
}

mat-radio-button.mat-radio-button .mat-radio-container {
  transform: scale(0.8);
}
mat-radio-button.mat-radio-button .mat-radio-container .mat-radio-inner-circle {
  background-color: #000000;
}
mat-radio-button.mat-radio-button .mat-radio-container .mat-radio-outer-circle {
  border-width: 1px;
}
mat-radio-button.mat-radio-button .mat-radio-label-content {
  padding-left: 2px;
}
mat-radio-button.mat-radio-button.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ffffff;
}
mat-radio-button.mat-radio-button.mat-radio-checked .mat-radio-inner-circle {
  transform: scale(0.625);
}
mat-radio-button.mat-radio-button.mat-radio-disabled .mat-radio-label {
  cursor: not-allowed;
}
mat-radio-button.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle, mat-radio-button.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle {
  border-color: #bdbdbd;
}
mat-radio-button.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: #bdbdbd;
}
mat-radio-button.mat-radio-button .mat-ripple {
  display: none;
}

.mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}

.mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}

.mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}

.mat-select-panel {
  background: white;
}
.mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #0868ac;
}
.mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #4a4a4a;
}
.mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #d50000;
}
.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #d50000;
}
.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}

.xpo-Select-twoLinesContainer {
  color: #212121;
  display: flex;
  flex-direction: column;
  margin-left: 8px;
}
.xpo-Select-twoLinesContainer .mat-option-text:nth-of-type(1) {
  color: #000000;
  line-height: 16px;
}
.xpo-Select-twoLinesContainer .mat-option-text:nth-of-type(2) {
  color: #333333;
  font-size: 11px;
  line-height: 12px;
  padding-top: 4px;
}

.xpo-Select-twoColumnsContainer {
  display: flex;
  width: 100%;
}
.xpo-Select-twoColumnsContainer span:first-of-type {
  flex: 1;
}
.xpo-Select-twoColumnsContainer span:last-of-type {
  flex: 2;
}

.xpo-Select-panel.mat-select-panel {
  border: 1px solid #d8d8d8;
  border-radius: 0;
  border-top: none;
  box-shadow: none;
  margin-left: 7px;
  margin-top: 24px;
}
.xpo-Select-panel.mat-select-panel .mat-option,
.xpo-Select-panel.mat-select-panel .mat-optgroup-label {
  height: 30px;
  line-height: normal;
  padding: 6px 8px;
}
.xpo-Select-panel.mat-select-panel .mat-option:not(:last-of-type),
.xpo-Select-panel.mat-select-panel .mat-optgroup-label:not(:last-of-type) {
  border-bottom: 1px solid #d8d8d8;
}
.xpo-Select-panel.mat-select-panel .mat-option.xpo-Option--twoLines,
.xpo-Select-panel.mat-select-panel .mat-optgroup-label.xpo-Option--twoLines {
  height: 45px;
}
.xpo-Select-panel.mat-select-panel .mat-option.xpo-Option--twoLines .mat-option-text,
.xpo-Select-panel.mat-select-panel .mat-optgroup-label.xpo-Option--twoLines .mat-option-text {
  display: flex;
  flex-direction: row;
}
.xpo-Select-panel.mat-select-panel .mat-option.xpo-Option-hideDivider:not(:last-of-type),
.xpo-Select-panel.mat-select-panel .mat-optgroup-label.xpo-Option-hideDivider:not(:last-of-type) {
  border-bottom: none;
}
.xpo-Select-panel.mat-select-panel .mat-option:hover:not(.mat-option-disabled), .xpo-Select-panel.mat-select-panel .mat-option:focus:not(.mat-option-disabled),
.xpo-Select-panel.mat-select-panel .mat-optgroup-label:hover:not(.mat-option-disabled),
.xpo-Select-panel.mat-select-panel .mat-optgroup-label:focus:not(.mat-option-disabled) {
  background: #e4effa;
}
.xpo-Select-panel.mat-select-panel .mat-option .mat-icon,
.xpo-Select-panel.mat-select-panel .mat-optgroup-label .mat-icon {
  margin-right: 0;
}
.xpo-Select-panel.mat-select-panel .mat-option.mat-selected:not(.mat-option-disabled),
.xpo-Select-panel.mat-select-panel .mat-optgroup-label.mat-selected:not(.mat-option-disabled) {
  background: #e4effa;
  color: #4a4a4a;
}
.xpo-Select-panel.mat-select-panel .mat-option-disabled,
.xpo-Select-panel.mat-select-panel .mat-optgroup-label-disabled {
  background-color: #f6f6f6;
}
.xpo-Select-panel.mat-select-panel .mat-option .mat-option-text,
.xpo-Select-panel.mat-select-panel .mat-optgroup-label .mat-option-text {
  align-items: center;
  color: #333333;
}
.xpo-Select-panel.mat-select-panel .mat-option .mat-pseudo-checkbox,
.xpo-Select-panel.mat-select-panel .mat-optgroup-label .mat-pseudo-checkbox {
  border: 1px solid #d8d8d8;
  margin-right: 6px;
}
.xpo-Select-panel.mat-select-panel .mat-option .mat-pseudo-checkbox-disabled,
.xpo-Select-panel.mat-select-panel .mat-optgroup-label .mat-pseudo-checkbox-disabled {
  background-color: #d8d8d8;
  border-color: #d8d8d8;
}
.xpo-Select-panel.mat-select-panel .mat-option .mat-pseudo-checkbox-disabled::after,
.xpo-Select-panel.mat-select-panel .mat-optgroup-label .mat-pseudo-checkbox-disabled::after {
  border-left: 2px solid currentColor;
  box-sizing: content-box;
  height: 3px;
  opacity: 1;
  transform: rotate(-45deg);
  width: 8px;
}
.xpo-Select-panel.mat-select-panel .mat-option .mat-pseudo-checkbox-checked,
.xpo-Select-panel.mat-select-panel .mat-optgroup-label .mat-pseudo-checkbox-checked {
  background-color: #000000;
  border-color: #000000;
}
.xpo-Select-panel.mat-select-panel .mat-option .mat-pseudo-checkbox::after,
.xpo-Select-panel.mat-select-panel .mat-optgroup-label .mat-pseudo-checkbox::after {
  left: 2px;
  top: 3.4px;
}
.xpo-Select-panel.mat-select-panel .mat-optgroup-label {
  border-bottom: 1px solid #d8d8d8;
  color: #333333;
  font-weight: 500;
}
.xpo-Select-panel.mat-select-panel .mat-optgroup:not(:first-of-type) .mat-optgroup-label {
  border-top: 1px solid #d8d8d8;
}

.mat-option .mat-option-ripple {
  display: none !important;
}

.mat-form-field.mat-form-field-type-mat-select.mat-form-field-appearance-standard .mat-form-field-flex {
  align-items: center;
  padding-top: 0;
}
.mat-form-field.mat-form-field-type-mat-select.mat-form-field-appearance-legacy, .mat-form-field.mat-form-field-type-mat-select.mat-form-field-appearance-standard {
  margin-bottom: 16px;
  margin-top: 24px;
}
.mat-form-field.mat-form-field-type-mat-select.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-wrapper .mat-form-field-infix, .mat-form-field.mat-form-field-type-mat-select.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-wrapper .mat-form-field-infix {
  background-color: #f6f6f6;
  cursor: not-allowed;
  font-style: italic;
}
.mat-form-field.mat-form-field-type-mat-select.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-select-placeholder,
.mat-form-field.mat-form-field-type-mat-select.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-select-trigger, .mat-form-field.mat-form-field-type-mat-select.mat-form-field-appearance-standard.mat-form-field-disabled .mat-select-placeholder,
.mat-form-field.mat-form-field-type-mat-select.mat-form-field-appearance-standard.mat-form-field-disabled .mat-select-trigger {
  color: #000000;
  cursor: not-allowed;
}
.mat-form-field.mat-form-field-type-mat-select.mat-form-field-appearance-legacy.mat-form-field-has-label .mat-select:not(.mat-select-empty) .mat-select-arrow-wrapper, .mat-form-field.mat-form-field-type-mat-select.mat-form-field-appearance-standard.mat-form-field-has-label .mat-select:not(.mat-select-empty) .mat-select-arrow-wrapper {
  transform: none;
}
.mat-form-field.mat-form-field-type-mat-select.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper, .mat-form-field.mat-form-field-type-mat-select.mat-form-field-appearance-standard .mat-form-field-subscript-wrapper {
  margin: 0;
  position: relative;
}
.mat-form-field.mat-form-field-type-mat-select.mat-form-field-appearance-legacy .mat-form-field-wrapper, .mat-form-field.mat-form-field-type-mat-select.mat-form-field-appearance-standard .mat-form-field-wrapper {
  height: unset;
  line-height: 16px;
  padding: 0;
}
.mat-focused .mat-form-field-infix {
  border-color: #000000 !important;
}

.mat-form-field-invalid .mat-form-field-infix {
  border-color: #d50000 !important;
}

.mat-form-field.mat-form-field-type-mat-select.mat-form-field-appearance-legacy .mat-form-field-wrapper .mat-form-field-infix, .mat-form-field.mat-form-field-type-mat-select.mat-form-field-appearance-standard .mat-form-field-wrapper .mat-form-field-infix {
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  border-radius: 2px;
  padding: 6px 8px;
}
.mat-form-field.mat-form-field-type-mat-select.mat-form-field-appearance-legacy .mat-form-field-wrapper .mat-form-field-infix .mat-select-arrow, .mat-form-field.mat-form-field-type-mat-select.mat-form-field-appearance-standard .mat-form-field-wrapper .mat-form-field-infix .mat-select-arrow {
  margin-right: 0;
}
.mat-form-field.mat-form-field-type-mat-select.mat-form-field-appearance-legacy .mat-form-field-wrapper .mat-form-field-infix .mat-select-value-text, .mat-form-field.mat-form-field-type-mat-select.mat-form-field-appearance-standard .mat-form-field-wrapper .mat-form-field-infix .mat-select-value-text {
  color: #212121;
}
.mat-form-field.mat-form-field-type-mat-select.mat-form-field-appearance-legacy.mat-focused .mat-select-arrow, .mat-form-field.mat-form-field-type-mat-select.mat-form-field-appearance-standard.mat-focused .mat-select-arrow {
  color: #4a4a4a !important;
}
.mat-form-field.mat-form-field-type-mat-select.mat-form-field-appearance-legacy.ngx-FormField--inline .mat-form-field-wrapper, .mat-form-field.mat-form-field-type-mat-select.mat-form-field-appearance-standard.ngx-FormField--inline .mat-form-field-wrapper {
  margin-bottom: 0;
  margin-top: 0;
}
.mat-form-field.mat-form-field-type-mat-select.mat-form-field-appearance-legacy:not(.mat-form-field-should-float) .mat-form-field-label, .mat-form-field.mat-form-field-type-mat-select.mat-form-field-appearance-standard:not(.mat-form-field-should-float) .mat-form-field-label {
  color: #9b9b9b;
}
.mat-form-field.mat-form-field-type-mat-select.mat-form-field-appearance-legacy.mat-form-field-should-float .mat-form-field-label, .mat-form-field.mat-form-field-type-mat-select.mat-form-field-appearance-standard.mat-form-field-should-float .mat-form-field-label {
  color: #333333;
}
.mat-form-field.mat-form-field-type-mat-select.mat-form-field-appearance-legacy.mat-form-field-invalid .mat-form-field-label, .mat-form-field.mat-form-field-type-mat-select.mat-form-field-appearance-standard.mat-form-field-invalid .mat-form-field-label {
  color: #9b9b9b !important;
}
.mat-form-field.mat-form-field-type-mat-select.mat-form-field-appearance-legacy:not(.mat-form-field-should-float) .mat-form-field-label, .mat-form-field.mat-form-field-type-mat-select.mat-form-field-appearance-standard:not(.mat-form-field-should-float) .mat-form-field-label {
  color: #9b9b9b;
}
.mat-form-field.mat-form-field-type-mat-select.mat-form-field-appearance-legacy .mat-placeholder-required, .mat-form-field.mat-form-field-type-mat-select.mat-form-field-appearance-standard .mat-placeholder-required {
  color: #d50000;
}
.mat-form-field.mat-form-field-type-mat-select.mat-form-field-appearance-legacy .mat-hint, .mat-form-field.mat-form-field-type-mat-select.mat-form-field-appearance-standard .mat-hint {
  font-size: 0.875rem;
  font-style: italic;
  font-weight: 400;
  line-height: 16px;
}
.mat-form-field.mat-form-field-type-mat-select.mat-form-field-appearance-legacy .mat-error, .mat-form-field.mat-form-field-type-mat-select.mat-form-field-appearance-standard .mat-error {
  display: flex;
  font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 16px;
  margin-top: 8px;
}
.mat-form-field.mat-form-field-type-mat-select.mat-form-field-appearance-legacy .mat-error .mat-icon, .mat-form-field.mat-form-field-type-mat-select.mat-form-field-appearance-standard .mat-error .mat-icon {
  display: inline-flex;
  font-size: 20px;
  height: auto;
  line-height: 16px;
  margin-right: 4px;
  width: auto;
}
.mat-form-field.mat-form-field-type-mat-select.mat-form-field-appearance-legacy .mat-form-field-prefix, .mat-form-field.mat-form-field-type-mat-select.mat-form-field-appearance-standard .mat-form-field-prefix {
  padding-left: 0.3em;
}
.mat-form-field.mat-form-field-type-mat-select.mat-form-field-appearance-legacy .mat-form-field-suffix, .mat-form-field.mat-form-field-type-mat-select.mat-form-field-appearance-standard .mat-form-field-suffix {
  height: 24px;
  position: absolute;
  right: 24px;
  width: 24px;
}
.mat-form-field.mat-form-field-type-mat-select.mat-form-field-appearance-legacy.ngx-FormField--borderless .mat-form-field-wrapper, .mat-form-field.mat-form-field-type-mat-select.mat-form-field-appearance-standard.ngx-FormField--borderless .mat-form-field-wrapper {
  border: none;
  box-shadow: unset;
}
.mat-form-field.mat-form-field-type-mat-select.mat-form-field-appearance-legacy .mat-form-field-underline, .mat-form-field.mat-form-field-type-mat-select.mat-form-field-appearance-standard .mat-form-field-underline {
  display: none;
}

.xpo-Select-panel.mat-select-panel {
  min-width: calc(100% + 18px) !important;
}

.xpo-Select-panelMultiple.mat-select-panel {
  margin-left: 31px;
}

mat-slide-toggle.mat-slide-toggle.mat-disabled {
  opacity: unset;
}
mat-slide-toggle.mat-slide-toggle.mat-checked {
  opacity: unset;
}
mat-slide-toggle.mat-slide-toggle.mat-checked.mat-disabled .mat-slide-toggle-bar {
  background-color: #cde8cc;
  border: 1px solid #c7dec6;
}
mat-slide-toggle.mat-slide-toggle.mat-disabled .mat-slide-toggle-bar {
  background-color: #f9f9f9;
  border: 1px solid #e2e2e4;
}
mat-slide-toggle.mat-slide-toggle.mat-disabled .mat-slide-toggle-bar .mat-slide-toggle-thumb {
  box-shadow: 0 0 0 1px #e2e2e4;
}
mat-slide-toggle.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: #4caf50;
  border: 1px solid #388e3c;
}
mat-slide-toggle.mat-slide-toggle .mat-slide-toggle-bar {
  background-color: #f6f6f6;
  border: 1px solid #d8d8d8;
  border-radius: 30px;
  height: 22px;
  width: 38px;
}
mat-slide-toggle.mat-slide-toggle.mat-checked.mat-disabled .mat-slide-toggle-thumb {
  box-shadow: 0 0 0 1px #c7dec6;
}
mat-slide-toggle.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #ffffff;
  border: none;
  box-shadow: none;
}
mat-slide-toggle.mat-slide-toggle.mat-disabled .mat-slide-toggle-thumb {
  background-color: #ffffff;
}
mat-slide-toggle.mat-slide-toggle .mat-slide-toggle-thumb-container {
  cursor: pointer;
  left: 0;
  top: 0.1px;
}
mat-slide-toggle.mat-slide-toggle .mat-slide-toggle-thumb-container.mat-slide-toggle-thumb {
  background-color: #ffffff;
  height: 22px;
  width: 22px;
}
mat-slide-toggle.mat-slide-toggle .mat-slide-toggle-thumb {
  background-color: #ffffff;
  box-shadow: 0 0 0 1px #d8d8d8;
}
mat-slide-toggle.mat-slide-toggle.xpo-SlideToggle--small.mat-checked.mat-disabled .mat-slide-toggle-bar {
  background-color: #cde8cc;
  border: 1px solid #c7dec6;
}
mat-slide-toggle.mat-slide-toggle.xpo-SlideToggle--small.mat-disabled .mat-slide-toggle-bar {
  background-color: #f9f9f9;
  border: 1px solid #e2e2e4;
}
mat-slide-toggle.mat-slide-toggle.xpo-SlideToggle--small.mat-checked .mat-slide-toggle-bar {
  background-color: #4caf50;
  border: 1px solid #388e3c;
}
mat-slide-toggle.mat-slide-toggle.xpo-SlideToggle--small .mat-slide-toggle-bar {
  background-color: #f6f6f6;
  border-radius: 30px;
  height: 16px;
  width: 32px;
}
mat-slide-toggle.mat-slide-toggle.xpo-SlideToggle--small .mat-slide-toggle-thumb-container {
  height: 14px;
  width: 14px;
}
mat-slide-toggle.mat-slide-toggle.xpo-SlideToggle--small .mat-slide-toggle-thumb {
  height: 14px;
  width: 14px;
}
mat-slide-toggle.mat-slide-toggle .mat-slide-toggle-ripple {
  display: none;
}

.xpo-Ssi {
  display: inline-flex;
}
.xpo-Ssi .xpo-Icon {
  width: auto;
}
.xpo-Ssi .xpo-Icon .mat-icon svg {
  width: auto;
}

.mat-drawer.xpo-TrayDrawer {
  max-width: 10%;
  min-width: 500px;
  overflow: hidden;
  width: 10%;
}
.mat-drawer.xpo-TrayDrawer.xpo-TrayDrawer--extended {
  max-width: 90%;
  width: 90%;
}
.mat-drawer.xpo-TrayDrawer:not(.mat-drawer-side), .mat-drawer.xpo-TrayDrawer.mat-drawer-side {
  border: 1px solid #d8d8d8;
  box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.35);
}
.mat-drawer.xpo-TrayDrawer .mat-drawer-inner-container {
  overflow: hidden;
}

.cdk-overlay-container .mat-tooltip-panel .mat-tooltip {
  background: #212121;
  border-radius: 0;
  color: #ffffff;
  display: flex;
  font-size: 1rem;
  line-height: 18px;
  overflow: visible;
  padding: 16px;
  position: relative;
  white-space: pre-line;
}
.cdk-overlay-container .mat-tooltip-panel[style*=bottom][style*=left] .mat-tooltip[style*=center][style*=bottom] {
  margin-bottom: 18px;
}
.cdk-overlay-container .mat-tooltip-panel[style*=bottom][style*=left] .mat-tooltip[style*=center][style*=bottom]::after {
  border-bottom: none;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #212121;
  content: "";
  height: 0;
  left: 50%;
  position: absolute;
  top: 100%;
  transform: translateX(-50%);
  width: 0;
}
.cdk-overlay-container .mat-tooltip-panel[style*=top][style*=left] .mat-tooltip[style*=center][style*=top] {
  margin-top: 18px;
}
.cdk-overlay-container .mat-tooltip-panel[style*=top][style*=left] .mat-tooltip[style*=center][style*=top]::before {
  border-bottom: 10px solid #212121;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: none;
  bottom: 100%;
  content: "";
  height: 0;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 0;
}
.cdk-overlay-container .mat-tooltip-panel[style*=top][style*=left] .mat-tooltip[style*=center][style*=left] {
  margin-left: 18px;
}
.cdk-overlay-container .mat-tooltip-panel[style*=top][style*=left] .mat-tooltip[style*=center][style*=left]::before {
  border-bottom: 10px solid transparent;
  border-left: 10px solid transparent;
  border-right: 10px solid #212121;
  border-top: 10px solid transparent;
  content: "";
  height: 0;
  position: absolute;
  right: 100%;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
}
.cdk-overlay-container .mat-tooltip-panel[style*=top][style*=right] .mat-tooltip[style*=center][style*=right] {
  margin-right: 18px;
}
.cdk-overlay-container .mat-tooltip-panel[style*=top][style*=right] .mat-tooltip[style*=center][style*=right]::after {
  border-bottom: 10px solid transparent;
  border-left: 10px solid #212121;
  border-right: 10px solid transparent;
  border-top: 10px solid transparent;
  content: "";
  height: 0;
  left: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
}

.xpo-ErrorPages-title h1 {
  font-size: 28px;
}
.xpo-ErrorPages-description {
  margin-bottom: 16px;
}
.xpo-ErrorPages-description p {
  font-size: 1.285rem;
}
.xpo-ErrorPages-action {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 32px;
  gap: 8px;
}
.xpo-ErrorPages-footer {
  width: 100%;
}

.mat-form-field-type-xpo-time-picker {
  width: 96px;
  margin-bottom: 6px;
  margin-top: 24px;
}
.mat-form-field-type-xpo-time-picker .xpo-Icon .mat-icon {
  color: #4a4a4a;
  cursor: pointer;
}
.mat-form-field-type-xpo-time-picker .xpo-Icon .mat-icon:hover {
  color: #9b9b9b;
}
.mat-form-field-type-xpo-time-picker .xpo-Icon .mat-icon:active {
  color: #4a4a4a;
}
.mat-form-field-type-xpo-time-picker .xpo-TimePicker:focus-within {
  border: solid 1px #000000;
}
.mat-form-field-type-xpo-time-picker .xpo-TimePicker.ng-touched.ng-invalid {
  border: solid 1px #d32f2f;
}
.mat-form-field-type-xpo-time-picker .mat-input-element:disabled {
  background-color: #f3f3f3;
  font-style: italic;
  font-weight: 400;
}
.mat-form-field-type-xpo-time-picker .mat-input-element:disabled ~ .xpo-Icon .mat-icon {
  color: #bdbdbd;
  cursor: not-allowed;
}
.mat-form-field-type-xpo-time-picker .mat-form-field-underline {
  display: none;
}
.mat-form-field-type-xpo-time-picker.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0;
  border-top: 0;
}
.mat-form-field-type-xpo-time-picker.mat-form-field-infix {
  padding: 0;
  border-top: 0;
}
.mat-form-field-type-xpo-time-picker.mat-form-field-wrapper {
  padding-bottom: 0;
  height: unset;
  line-height: 16px;
  padding: 0;
  top: 0;
}
.mat-form-field-type-xpo-time-picker.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float.mat-form-field-has-label .mat-form-field-flex .mat-form-field-label-wrapper .mat-form-field-label {
  line-height: 1.429rem;
  height: 20px;
}
.mat-form-field-type-xpo-time-picker.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #d32f2f;
}
.mat-form-field-type-xpo-time-picker .mat-error {
  color: #d32f2f;
  display: flex;
  font-size: 0.875rem;
  gap: 8px;
  line-height: 16px;
  width: 96px;
}
.mat-form-field-type-xpo-time-picker .mat-error .xpo-Icon .mat-icon {
  color: #d32f2f;
  display: inline-flex;
}
.mat-form-field-type-xpo-time-picker .mat-error > .mat-icon {
  color: #d32f2f;
  display: inline-flex;
  font-size: 20px;
  height: 20px;
}

.mat-autocomplete-panel.mat-autocomplete-visible.xpo-TimePicker-panel {
  border: solid 1px #000000;
  min-width: 96px;
  right: 1px;
  top: -1px;
  width: 96px;
}
.mat-autocomplete-panel.mat-autocomplete-visible.xpo-TimePicker-panel .mat-option {
  border: 0;
  color: #4a4a4a;
  line-height: 1.429rem;
}
.mat-autocomplete-panel.mat-autocomplete-visible.xpo-TimePicker-panel .mat-option.mat-active {
  background: #e4effa;
}

button.mat-button.xpo-DownloadButton {
  align-items: center;
  display: flex;
  height: 20px;
  min-width: 20px;
}
button.mat-button.xpo-DownloadButton .mat-spinner {
  display: inline-block;
}

.xpo-Header {
  background: whitesmoke;
  border-bottom: 1px solid #d8d8d8;
}

.mat-progress-bar-background {
  fill: #bed6e7;
}

.mat-progress-bar-buffer {
  background-color: #bed6e7;
}

.mat-progress-bar-fill::after {
  background-color: #0868ac;
}

.mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #cecece;
}
.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #cecece;
}
.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #4a4a4a;
}

.mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f1bcbc;
}
.mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f1bcbc;
}
.mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #d50000;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #0868ac;
}
.mat-progress-spinner.mat-accent circle, .mat-spinner.mat-accent circle {
  stroke: #4a4a4a;
}
.mat-progress-spinner.mat-warn circle, .mat-spinner.mat-warn circle {
  stroke: #d50000;
}

.mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-drawer.mat-drawer-push {
  background-color: white;
}
.mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}

[dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
[dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}

.mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}

.mat-calendar-table-header,
.mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}

.mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}

.mat-calendar-body-in-range::before {
  background: rgba(8, 104, 172, 0.2);
}

.mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}

.mat-calendar-body-comparison-bridge-start::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(8, 104, 172, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-comparison-bridge-end::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(8, 104, 172, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}

.mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}

.mat-calendar-body-selected {
  background-color: #0868ac;
  color: white;
}

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(8, 104, 172, 0.4);
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}

.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(8, 104, 172, 0.3);
}

@media (hover: hover) {
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(8, 104, 172, 0.3);
  }
}
.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(74, 74, 74, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(74, 74, 74, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(74, 74, 74, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #4a4a4a;
  color: white;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(74, 74, 74, 0.4);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(74, 74, 74, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(74, 74, 74, 0.3);
  }
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(213, 0, 0, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(213, 0, 0, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(213, 0, 0, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #d50000;
  color: white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(213, 0, 0, 0.4);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(213, 0, 0, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(213, 0, 0, 0.3);
  }
}

.mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-datepicker-toggle-active {
  color: #0868ac;
}
.mat-datepicker-toggle-active.mat-accent {
  color: #4a4a4a;
}
.mat-datepicker-toggle-active.mat-warn {
  color: #d50000;
}

.mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  width: 40px;
  height: 40px;
  padding: 8px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__ripple {
  width: 40px;
  height: 40px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
  margin-left: 0px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__touch {
  position: absolute;
  top: 50%;
  height: 40px;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  width: 40px;
  transform: translate(-50%, -50%);
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mat-mdc-button-touch-target {
  display: none;
}

.mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}

.mat-badge {
  position: relative;
}

.mat-badge.mat-badge {
  overflow: visible;
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

.mat-badge-content {
  color: white;
  background: #0868ac;
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-accent .mat-badge-content {
  background: #4a4a4a;
  color: white;
}

.mat-badge-warn .mat-badge-content {
  color: white;
  background: #d50000;
}

.mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}

mat-dialog-container.mat-dialog-container {
  padding: 0;
}

.u-flex--fillSpace {
  flex: 1;
}

.u-flex--spaceBetween {
  justify-content: space-between;
}

@font-face {
  font-family: "xpo-icons";
  src: url("./xpo-icons.woff2?1714731760090") format("woff2");
  font-weight: normal;
  font-style: normal;
}
.xpo-icon:before {
  display: inline-block;
  font-family: "xpo-icons";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.xpo-icon.xpo-icon-account-outline:before {
  content: "\ea01";
}

.xpo-icon.xpo-icon-account:before {
  content: "\ea02";
}

.xpo-icon.xpo-icon-add-alt:before {
  content: "\ea03";
}

.xpo-icon.xpo-icon-add-outline:before {
  content: "\ea04";
}

.xpo-icon.xpo-icon-add:before {
  content: "\ea05";
}

.xpo-icon.xpo-icon-alarm:before {
  content: "\ea06";
}

.xpo-icon.xpo-icon-app-switcher:before {
  content: "\ea07";
}

.xpo-icon.xpo-icon-archive:before {
  content: "\ea08";
}

.xpo-icon.xpo-icon-arrow-back:before {
  content: "\ea09";
}

.xpo-icon.xpo-icon-arrow-bottom-left:before {
  content: "\ea0a";
}

.xpo-icon.xpo-icon-arrow-bottom-right:before {
  content: "\ea0b";
}

.xpo-icon.xpo-icon-arrow-collapse:before {
  content: "\ea0c";
}

.xpo-icon.xpo-icon-arrow-downward:before {
  content: "\ea0d";
}

.xpo-icon.xpo-icon-arrow-drop-down:before {
  content: "\ea0e";
}

.xpo-icon.xpo-icon-arrow-drop-left:before {
  content: "\ea0f";
}

.xpo-icon.xpo-icon-arrow-drop-right:before {
  content: "\ea10";
}

.xpo-icon.xpo-icon-arrow-drop-up:before {
  content: "\ea11";
}

.xpo-icon.xpo-icon-arrow-expand:before {
  content: "\ea12";
}

.xpo-icon.xpo-icon-arrow-forward:before {
  content: "\ea13";
}

.xpo-icon.xpo-icon-arrow-from-bottom:before {
  content: "\ea14";
}

.xpo-icon.xpo-icon-arrow-from-left:before {
  content: "\ea15";
}

.xpo-icon.xpo-icon-arrow-from-right:before {
  content: "\ea16";
}

.xpo-icon.xpo-icon-arrow-from-top:before {
  content: "\ea17";
}

.xpo-icon.xpo-icon-arrow-top-left:before {
  content: "\ea18";
}

.xpo-icon.xpo-icon-arrow-top-right:before {
  content: "\ea19";
}

.xpo-icon.xpo-icon-arrow-upward:before {
  content: "\ea1a";
}

.xpo-icon.xpo-icon-arrows:before {
  content: "\ea1b";
}

.xpo-icon.xpo-icon-assign:before {
  content: "\ea1c";
}

.xpo-icon.xpo-icon-attach-file:before {
  content: "\ea1d";
}

.xpo-icon.xpo-icon-attach-money:before {
  content: "\ea1e";
}

.xpo-icon.xpo-icon-barcode-off:before {
  content: "\ea1f";
}

.xpo-icon.xpo-icon-barcode-scan:before {
  content: "\ea20";
}

.xpo-icon.xpo-icon-barcode:before {
  content: "\ea21";
}

.xpo-icon.xpo-icon-bids:before {
  content: "\ea22";
}

.xpo-icon.xpo-icon-browser-chrome:before {
  content: "\ea23";
}

.xpo-icon.xpo-icon-browser-firefox:before {
  content: "\ea24";
}

.xpo-icon.xpo-icon-browser-ie:before {
  content: "\ea25";
}

.xpo-icon.xpo-icon-browser-opera:before {
  content: "\ea26";
}

.xpo-icon.xpo-icon-browser-safari:before {
  content: "\ea27";
}

.xpo-icon.xpo-icon-business:before {
  content: "\ea28";
}

.xpo-icon.xpo-icon-calculator:before {
  content: "\ea29";
}

.xpo-icon.xpo-icon-calendar-clock:before {
  content: "\ea2a";
}

.xpo-icon.xpo-icon-calendar-range:before {
  content: "\ea2b";
}

.xpo-icon.xpo-icon-calendar:before {
  content: "\ea2c";
}

.xpo-icon.xpo-icon-camera:before {
  content: "\ea2d";
}

.xpo-icon.xpo-icon-cancel-outline:before {
  content: "\ea2e";
}

.xpo-icon.xpo-icon-cancel:before {
  content: "\ea2f";
}

.xpo-icon.xpo-icon-chart-bar:before {
  content: "\ea30";
}

.xpo-icon.xpo-icon-chart-bubble:before {
  content: "\ea31";
}

.xpo-icon.xpo-icon-chart-donut:before {
  content: "\ea32";
}

.xpo-icon.xpo-icon-chart-gantt:before {
  content: "\ea33";
}

.xpo-icon.xpo-icon-chart-line:before {
  content: "\ea34";
}

.xpo-icon.xpo-icon-chart-pie:before {
  content: "\ea35";
}

.xpo-icon.xpo-icon-checkbook:before {
  content: "\ea36";
}

.xpo-icon.xpo-icon-checkbox-checked:before {
  content: "\ea37";
}

.xpo-icon.xpo-icon-checkbox-indeterminate:before {
  content: "\ea38";
}

.xpo-icon.xpo-icon-checkbox-unchecked:before {
  content: "\ea39";
}

.xpo-icon.xpo-icon-checkmark-alt-outline:before {
  content: "\ea3a";
}

.xpo-icon.xpo-icon-checkmark-alt:before {
  content: "\ea3b";
}

.xpo-icon.xpo-icon-checkmark-double:before {
  content: "\ea3c";
}

.xpo-icon.xpo-icon-checkmark:before {
  content: "\ea3d";
}

.xpo-icon.xpo-icon-chevron-bottom:before {
  content: "\ea3e";
}

.xpo-icon.xpo-icon-chevron-left:before {
  content: "\ea3f";
}

.xpo-icon.xpo-icon-chevron-right:before {
  content: "\ea40";
}

.xpo-icon.xpo-icon-chevron-top:before {
  content: "\ea41";
}

.xpo-icon.xpo-icon-circle:before {
  content: "\ea42";
}

.xpo-icon.xpo-icon-clipboard:before {
  content: "\ea43";
}

.xpo-icon.xpo-icon-clock:before {
  content: "\ea44";
}

.xpo-icon.xpo-icon-close:before {
  content: "\ea45";
}

.xpo-icon.xpo-icon-code:before {
  content: "\ea46";
}

.xpo-icon.xpo-icon-comment:before {
  content: "\ea47";
}

.xpo-icon.xpo-icon-cone:before {
  content: "\ea48";
}

.xpo-icon.xpo-icon-contacts:before {
  content: "\ea49";
}

.xpo-icon.xpo-icon-conversation:before {
  content: "\ea4a";
}

.xpo-icon.xpo-icon-copy-all:before {
  content: "\ea4b";
}

.xpo-icon.xpo-icon-copy:before {
  content: "\ea4c";
}

.xpo-icon.xpo-icon-cube-scan:before {
  content: "\ea4d";
}

.xpo-icon.xpo-icon-cube:before {
  content: "\ea4e";
}

.xpo-icon.xpo-icon-customs-officer:before {
  content: "\ea4f";
}

.xpo-icon.xpo-icon-cut:before {
  content: "\ea50";
}

.xpo-icon.xpo-icon-dashboard:before {
  content: "\ea51";
}

.xpo-icon.xpo-icon-delete:before {
  content: "\ea52";
}

.xpo-icon.xpo-icon-dialpad:before {
  content: "\ea53";
}

.xpo-icon.xpo-icon-dollie:before {
  content: "\ea54";
}

.xpo-icon.xpo-icon-download:before {
  content: "\ea55";
}

.xpo-icon.xpo-icon-drag-horiz:before {
  content: "\ea56";
}

.xpo-icon.xpo-icon-drag-vert:before {
  content: "\ea57";
}

.xpo-icon.xpo-icon-draw-polygon:before {
  content: "\ea58";
}

.xpo-icon.xpo-icon-draw-square:before {
  content: "\ea59";
}

.xpo-icon.xpo-icon-duplicate:before {
  content: "\ea5a";
}

.xpo-icon.xpo-icon-earth-search:before {
  content: "\ea5b";
}

.xpo-icon.xpo-icon-earth:before {
  content: "\ea5c";
}

.xpo-icon.xpo-icon-edit:before {
  content: "\ea5d";
}

.xpo-icon.xpo-icon-error:before {
  content: "\ea5e";
}

.xpo-icon.xpo-icon-favorite-outline:before {
  content: "\ea5f";
}

.xpo-icon.xpo-icon-favorite:before {
  content: "\ea60";
}

.xpo-icon.xpo-icon-feature-search:before {
  content: "\ea61";
}

.xpo-icon.xpo-icon-file-arrow:before {
  content: "\ea62";
}

.xpo-icon.xpo-icon-file-excel:before {
  content: "\ea63";
}

.xpo-icon.xpo-icon-file-image:before {
  content: "\ea64";
}

.xpo-icon.xpo-icon-file-money:before {
  content: "\ea65";
}

.xpo-icon.xpo-icon-file-pdf-new:before {
  content: "\ea66";
}

.xpo-icon.xpo-icon-file-pdf:before {
  content: "\ea67";
}

.xpo-icon.xpo-icon-file-powerpoint:before {
  content: "\ea68";
}

.xpo-icon.xpo-icon-file-table:before {
  content: "\ea69";
}

.xpo-icon.xpo-icon-file-video:before {
  content: "\ea6a";
}

.xpo-icon.xpo-icon-file-word:before {
  content: "\ea6b";
}

.xpo-icon.xpo-icon-file:before {
  content: "\ea6c";
}

.xpo-icon.xpo-icon-filter-column:before {
  content: "\ea6d";
}

.xpo-icon.xpo-icon-filter-toolbar-outline:before {
  content: "\ea6e";
}

.xpo-icon.xpo-icon-filter-toolbar:before {
  content: "\ea6f";
}

.xpo-icon.xpo-icon-first-page:before {
  content: "\ea70";
}

.xpo-icon.xpo-icon-flag-race:before {
  content: "\ea71";
}

.xpo-icon.xpo-icon-flag:before {
  content: "\ea72";
}

.xpo-icon.xpo-icon-forward:before {
  content: "\ea73";
}

.xpo-icon.xpo-icon-fullscreen-exit:before {
  content: "\ea74";
}

.xpo-icon.xpo-icon-fullscreen:before {
  content: "\ea75";
}

.xpo-icon.xpo-icon-gas-station:before {
  content: "\ea76";
}

.xpo-icon.xpo-icon-gray-cone:before {
  content: "\ea77";
}

.xpo-icon.xpo-icon-green-cone:before {
  content: "\ea78";
}

.xpo-icon.xpo-icon-guaranteed-generic:before {
  content: "\ea79";
}

.xpo-icon.xpo-icon-hand-truck:before {
  content: "\ea7a";
}

.xpo-icon.xpo-icon-heatmap-deliveries:before {
  content: "\ea7b";
}

.xpo-icon.xpo-icon-heatmap-pickups:before {
  content: "\ea7c";
}

.xpo-icon.xpo-icon-help-outline:before {
  content: "\ea7d";
}

.xpo-icon.xpo-icon-help:before {
  content: "\ea7e";
}

.xpo-icon.xpo-icon-history:before {
  content: "\ea7f";
}

.xpo-icon.xpo-icon-icon-ak:before {
  content: "\ea80";
}

.xpo-icon.xpo-icon-icon-hi:before {
  content: "\ea81";
}

.xpo-icon.xpo-icon-icon-pr:before {
  content: "\ea82";
}

.xpo-icon.xpo-icon-info:before {
  content: "\ea83";
}

.xpo-icon.xpo-icon-lane:before {
  content: "\ea84";
}

.xpo-icon.xpo-icon-last-page:before {
  content: "\ea85";
}

.xpo-icon.xpo-icon-layers:before {
  content: "\ea86";
}

.xpo-icon.xpo-icon-library:before {
  content: "\ea87";
}

.xpo-icon.xpo-icon-lightbulb:before {
  content: "\ea88";
}

.xpo-icon.xpo-icon-link:before {
  content: "\ea89";
}

.xpo-icon.xpo-icon-list-alt:before {
  content: "\ea8a";
}

.xpo-icon.xpo-icon-list-ordered:before {
  content: "\ea8b";
}

.xpo-icon.xpo-icon-list-unordered:before {
  content: "\ea8c";
}

.xpo-icon.xpo-icon-list:before {
  content: "\ea8d";
}

.xpo-icon.xpo-icon-locked:before {
  content: "\ea8e";
}

.xpo-icon.xpo-icon-loop:before {
  content: "\ea8f";
}

.xpo-icon.xpo-icon-mail:before {
  content: "\ea90";
}

.xpo-icon.xpo-icon-map-marker-outline:before {
  content: "\ea91";
}

.xpo-icon.xpo-icon-map-marker:before {
  content: "\ea92";
}

.xpo-icon.xpo-icon-map:before {
  content: "\ea93";
}

.xpo-icon.xpo-icon-marker-path:before {
  content: "\ea94";
}

.xpo-icon.xpo-icon-maths-rate:before {
  content: "\ea95";
}

.xpo-icon.xpo-icon-maximize:before {
  content: "\ea96";
}

.xpo-icon.xpo-icon-merge:before {
  content: "\ea97";
}

.xpo-icon.xpo-icon-message-unread:before {
  content: "\ea98";
}

.xpo-icon.xpo-icon-message:before {
  content: "\ea99";
}

.xpo-icon.xpo-icon-mic:before {
  content: "\ea9a";
}

.xpo-icon.xpo-icon-minimize:before {
  content: "\ea9b";
}

.xpo-icon.xpo-icon-moon:before {
  content: "\ea9c";
}

.xpo-icon.xpo-icon-more-horiz:before {
  content: "\ea9d";
}

.xpo-icon.xpo-icon-more-vert:before {
  content: "\ea9e";
}

.xpo-icon.xpo-icon-note:before {
  content: "\ea9f";
}

.xpo-icon.xpo-icon-notifications-active-outline:before {
  content: "\eaa0";
}

.xpo-icon.xpo-icon-notifications-active:before {
  content: "\eaa1";
}

.xpo-icon.xpo-icon-notifications-outline:before {
  content: "\eaa2";
}

.xpo-icon.xpo-icon-notifications-unread:before {
  content: "\eaa3";
}

.xpo-icon.xpo-icon-notifications:before {
  content: "\eaa4";
}

.xpo-icon.xpo-icon-ocean:before {
  content: "\eaa5";
}

.xpo-icon.xpo-icon-open-in-new:before {
  content: "\eaa6";
}

.xpo-icon.xpo-icon-orange-cone:before {
  content: "\eaa7";
}

.xpo-icon.xpo-icon-paste:before {
  content: "\eaa8";
}

.xpo-icon.xpo-icon-pause-alt:before {
  content: "\eaa9";
}

.xpo-icon.xpo-icon-pause:before {
  content: "\eaaa";
}

.xpo-icon.xpo-icon-people-alert:before {
  content: "\eaab";
}

.xpo-icon.xpo-icon-people-forward:before {
  content: "\eaac";
}

.xpo-icon.xpo-icon-people-outline:before {
  content: "\eaad";
}

.xpo-icon.xpo-icon-people:before {
  content: "\eaae";
}

.xpo-icon.xpo-icon-person-search:before {
  content: "\eaaf";
}

.xpo-icon.xpo-icon-phone:before {
  content: "\eab0";
}

.xpo-icon.xpo-icon-pickup-cluster:before {
  content: "\eab1";
}

.xpo-icon.xpo-icon-pickup-request:before {
  content: "\eab2";
}

.xpo-icon.xpo-icon-pin:before {
  content: "\eab3";
}

.xpo-icon.xpo-icon-play-alt:before {
  content: "\eab4";
}

.xpo-icon.xpo-icon-play:before {
  content: "\eab5";
}

.xpo-icon.xpo-icon-postal-lookup:before {
  content: "\eab6";
}

.xpo-icon.xpo-icon-price-check:before {
  content: "\eab7";
}

.xpo-icon.xpo-icon-print:before {
  content: "\eab8";
}

.xpo-icon.xpo-icon-priority-high:before {
  content: "\eab9";
}

.xpo-icon.xpo-icon-qr-code-scan:before {
  content: "\eaba";
}

.xpo-icon.xpo-icon-qr-code:before {
  content: "\eabb";
}

.xpo-icon.xpo-icon-quick-reference:before {
  content: "\eabc";
}

.xpo-icon.xpo-icon-radio-empty:before {
  content: "\eabd";
}

.xpo-icon.xpo-icon-radio-filled:before {
  content: "\eabe";
}

.xpo-icon.xpo-icon-reassign:before {
  content: "\eabf";
}

.xpo-icon.xpo-icon-receipt-long:before {
  content: "\eac0";
}

.xpo-icon.xpo-icon-red-cone:before {
  content: "\eac1";
}

.xpo-icon.xpo-icon-redo:before {
  content: "\eac2";
}

.xpo-icon.xpo-icon-refresh:before {
  content: "\eac3";
}

.xpo-icon.xpo-icon-remove-alt:before {
  content: "\eac4";
}

.xpo-icon.xpo-icon-remove:before {
  content: "\eac5";
}

.xpo-icon.xpo-icon-replay:before {
  content: "\eac6";
}

.xpo-icon.xpo-icon-reply-all:before {
  content: "\eac7";
}

.xpo-icon.xpo-icon-reply:before {
  content: "\eac8";
}

.xpo-icon.xpo-icon-report-issue:before {
  content: "\eac9";
}

.xpo-icon.xpo-icon-restart:before {
  content: "\eaca";
}

.xpo-icon.xpo-icon-row-collapse:before {
  content: "\eacb";
}

.xpo-icon.xpo-icon-row-expand:before {
  content: "\eacc";
}

.xpo-icon.xpo-icon-row-height:before {
  content: "\eacd";
}

.xpo-icon.xpo-icon-satellite:before {
  content: "\eace";
}

.xpo-icon.xpo-icon-save:before {
  content: "\eacf";
}

.xpo-icon.xpo-icon-search:before {
  content: "\ead0";
}

.xpo-icon.xpo-icon-send:before {
  content: "\ead1";
}

.xpo-icon.xpo-icon-sequence-lock:before {
  content: "\ead2";
}

.xpo-icon.xpo-icon-sequence:before {
  content: "\ead3";
}

.xpo-icon.xpo-icon-settings-outline:before {
  content: "\ead4";
}

.xpo-icon.xpo-icon-settings:before {
  content: "\ead5";
}

.xpo-icon.xpo-icon-share:before {
  content: "\ead6";
}

.xpo-icon.xpo-icon-shipment:before {
  content: "\ead7";
}

.xpo-icon.xpo-icon-sort-alphabetical-ascending:before {
  content: "\ead8";
}

.xpo-icon.xpo-icon-sort-alphabetical-descending:before {
  content: "\ead9";
}

.xpo-icon.xpo-icon-sort-ascending:before {
  content: "\eada";
}

.xpo-icon.xpo-icon-sort-descending:before {
  content: "\eadb";
}

.xpo-icon.xpo-icon-sort-numeric-ascending:before {
  content: "\eadc";
}

.xpo-icon.xpo-icon-sort-numeric-descending:before {
  content: "\eadd";
}

.xpo-icon.xpo-icon-sort:before {
  content: "\eade";
}

.xpo-icon.xpo-icon-split:before {
  content: "\eadf";
}

.xpo-icon.xpo-icon-spot-and-drop:before {
  content: "\eae0";
}

.xpo-icon.xpo-icon-star-half:before {
  content: "\eae1";
}

.xpo-icon.xpo-icon-stop-alt:before {
  content: "\eae2";
}

.xpo-icon.xpo-icon-stop:before {
  content: "\eae3";
}

.xpo-icon.xpo-icon-sun:before {
  content: "\eae4";
}

.xpo-icon.xpo-icon-swap:before {
  content: "\eae5";
}

.xpo-icon.xpo-icon-table-large:before {
  content: "\eae6";
}

.xpo-icon.xpo-icon-table-of-contents:before {
  content: "\eae7";
}

.xpo-icon.xpo-icon-table-view:before {
  content: "\eae8";
}

.xpo-icon.xpo-icon-table:before {
  content: "\eae9";
}

.xpo-icon.xpo-icon-timeline:before {
  content: "\eaea";
}

.xpo-icon.xpo-icon-tools:before {
  content: "\eaeb";
}

.xpo-icon.xpo-icon-traffic:before {
  content: "\eaec";
}

.xpo-icon.xpo-icon-trailer:before {
  content: "\eaed";
}

.xpo-icon.xpo-icon-training:before {
  content: "\eaee";
}

.xpo-icon.xpo-icon-trip-destination:before {
  content: "\eaef";
}

.xpo-icon.xpo-icon-trip-origin:before {
  content: "\eaf0";
}

.xpo-icon.xpo-icon-trip-stop:before {
  content: "\eaf1";
}

.xpo-icon.xpo-icon-truck:before {
  content: "\eaf2";
}

.xpo-icon.xpo-icon-tune:before {
  content: "\eaf3";
}

.xpo-icon.xpo-icon-unarchive:before {
  content: "\eaf4";
}

.xpo-icon.xpo-icon-unassign:before {
  content: "\eaf5";
}

.xpo-icon.xpo-icon-undo:before {
  content: "\eaf6";
}

.xpo-icon.xpo-icon-unlocked:before {
  content: "\eaf7";
}

.xpo-icon.xpo-icon-update:before {
  content: "\eaf8";
}

.xpo-icon.xpo-icon-upload:before {
  content: "\eaf9";
}

.xpo-icon.xpo-icon-van:before {
  content: "\eafa";
}

.xpo-icon.xpo-icon-view-cards-comfortable:before {
  content: "\eafb";
}

.xpo-icon.xpo-icon-view-cards-condensed:before {
  content: "\eafc";
}

.xpo-icon.xpo-icon-view-cards-default:before {
  content: "\eafd";
}

.xpo-icon.xpo-icon-view-grid:before {
  content: "\eafe";
}

.xpo-icon.xpo-icon-view-split-vertical:before {
  content: "\eaff";
}

.xpo-icon.xpo-icon-visibilty-off:before {
  content: "\eb00";
}

.xpo-icon.xpo-icon-visibilty:before {
  content: "\eb01";
}

.xpo-icon.xpo-icon-warning:before {
  content: "\eb02";
}

.xpo-icon.xpo-icon-weight:before {
  content: "\eb03";
}

.xpo-icon.xpo-icon-wifi-off:before {
  content: "\eb04";
}

.xpo-icon.xpo-icon-yellow-cone:before {
  content: "\eb05";
}

.xpo-icon.xpo-icon-zoom-in:before {
  content: "\eb06";
}

.xpo-icon.xpo-icon-zoom-out-map:before {
  content: "\eb07";
}

.xpo-icon.xpo-icon-zoom-out:before {
  content: "\eb08";
}

.xpo-icon.xpo-icon-ss-med-orange:before {
  content: "\eb09";
}

.xpo-icon.xpo-icon-ss-med-red:before {
  content: "\eb0a";
}

.xpo-icon.xpo-icon-ss_after_hours:before {
  content: "\eb0b";
}

.xpo-icon.xpo-icon-ss_appointment:before {
  content: "\eb0c";
}

.xpo-icon.xpo-icon-ss_appointment_pending:before {
  content: "\eb0d";
}

.xpo-icon.xpo-icon-ss_bond_status_clear:before {
  content: "\eb0e";
}

.xpo-icon.xpo-icon-ss_bond_status_unclear:before {
  content: "\eb0f";
}

.xpo-icon.xpo-icon-ss_bulk_liquid:before {
  content: "\eb10";
}

.xpo-icon.xpo-icon-ss_bulkliquid:before {
  content: "\eb11";
}

.xpo-icon.xpo-icon-ss_bypass:before {
  content: "\eb12";
}

.xpo-icon.xpo-icon-ss_cartage:before {
  content: "\eb13";
}

.xpo-icon.xpo-icon-ss_cash_on_delivery:before {
  content: "\eb14";
}

.xpo-icon.xpo-icon-ss_commercial_driver_license_expired:before {
  content: "\eb15";
}

.xpo-icon.xpo-icon-ss_commercial_driver_license_expiring_soon:before {
  content: "\eb16";
}

.xpo-icon.xpo-icon-ss_commercial_driver_license_valid:before {
  content: "\eb17";
}

.xpo-icon.xpo-icon-ss_construction_and_utility_sites_delivery:before {
  content: "\eb18";
}

.xpo-icon.xpo-icon-ss_customs_in_bond:before {
  content: "\eb19";
}

.xpo-icon.xpo-icon-ss_dbl-trp-orange:before {
  content: "\eb1a";
}

.xpo-icon.xpo-icon-ss_dbl-trp-red:before {
  content: "\eb1b";
}

.xpo-icon.xpo-icon-ss_destination_appointment:before {
  content: "\eb1c";
}

.xpo-icon.xpo-icon-ss_divert:before {
  content: "\eb1d";
}

.xpo-icon.xpo-icon-ss_driver_collect:before {
  content: "\eb1e";
}

.xpo-icon.xpo-icon-ss_empty:before {
  content: "\eb1f";
}

.xpo-icon.xpo-icon-ss_excessive_length:before {
  content: "\eb20";
}

.xpo-icon.xpo-icon-ss_exclusive:before {
  content: "\eb21";
}

.xpo-icon.xpo-icon-ss_expedite:before {
  content: "\eb22";
}

.xpo-icon.xpo-icon-ss_fast_id_endorsement_expired:before {
  content: "\eb23";
}

.xpo-icon.xpo-icon-ss_fast_id_endorsement_expiring_soon:before {
  content: "\eb24";
}

.xpo-icon.xpo-icon-ss_fast_id_endorsement_valid:before {
  content: "\eb25";
}

.xpo-icon.xpo-icon-ss_food:before {
  content: "\eb26";
}

.xpo-icon.xpo-icon-ss_fork_lift:before {
  content: "\eb27";
}

.xpo-icon.xpo-icon-ss_freezable:before {
  content: "\eb28";
}

.xpo-icon.xpo-icon-ss_guaranteed:before {
  content: "\eb29";
}

.xpo-icon.xpo-icon-ss_guaranteed_by_noon:before {
  content: "\eb2a";
}

.xpo-icon.xpo-icon-ss_hazmat:before {
  content: "\eb2b";
}

.xpo-icon.xpo-icon-ss_hazmat_endorsement_expired:before {
  content: "\eb2c";
}

.xpo-icon.xpo-icon-ss_hazmat_endorsement_expiring_soon:before {
  content: "\eb2d";
}

.xpo-icon.xpo-icon-ss_hazmat_endorsement_valid:before {
  content: "\eb2e";
}

.xpo-icon.xpo-icon-ss_headload:before {
  content: "\eb2f";
}

.xpo-icon.xpo-icon-ss_highway_sub_service:before {
  content: "\eb30";
}

.xpo-icon.xpo-icon-ss_inside_delivery:before {
  content: "\eb31";
}

.xpo-icon.xpo-icon-ss_inside_pickup:before {
  content: "\eb32";
}

.xpo-icon.xpo-icon-ss_lift_gate:before {
  content: "\eb33";
}

.xpo-icon.xpo-icon-ss_linehaul:before {
  content: "\eb34";
}

.xpo-icon.xpo-icon-ss_live:before {
  content: "\eb35";
}

.xpo-icon.xpo-icon-ss_live_delivery:before {
  content: "\eb36";
}

.xpo-icon.xpo-icon-ss_mabd:before {
  content: "\eb37";
}

.xpo-icon.xpo-icon-ss_misload:before {
  content: "\eb38";
}

.xpo-icon.xpo-icon-ss_moover:before {
  content: "\eb39";
}

.xpo-icon.xpo-icon-ss_notify_on_arrival:before {
  content: "\eb3a";
}

.xpo-icon.xpo-icon-ss_on_time:before {
  content: "\eb3b";
}

.xpo-icon.xpo-icon-ss_pallet_jack:before {
  content: "\eb3c";
}

.xpo-icon.xpo-icon-ss_partial:before {
  content: "\eb3d";
}

.xpo-icon.xpo-icon-ss_partial_bypass:before {
  content: "\eb3e";
}

.xpo-icon.xpo-icon-ss_premium:before {
  content: "\eb3f";
}

.xpo-icon.xpo-icon-ss_premium_services:before {
  content: "\eb40";
}

.xpo-icon.xpo-icon-ss_premium_support_service:before {
  content: "\eb41";
}

.xpo-icon.xpo-icon-ss_priority_1:before {
  content: "\eb42";
}

.xpo-icon.xpo-icon-ss_priority_2:before {
  content: "\eb43";
}

.xpo-icon.xpo-icon-ss_pup_only:before {
  content: "\eb44";
}

.xpo-icon.xpo-icon-ss_pure:before {
  content: "\eb45";
}

.xpo-icon.xpo-icon-ss_rapid_remote_service:before {
  content: "\eb46";
}

.xpo-icon.xpo-icon-ss_residential_delivery:before {
  content: "\eb47";
}

.xpo-icon.xpo-icon-ss_retail:before {
  content: "\eb48";
}

.xpo-icon.xpo-icon-ss_retail_rollout:before {
  content: "\eb49";
}

.xpo-icon.xpo-icon-ss_show:before {
  content: "\eb4a";
}

.xpo-icon.xpo-icon-ss_storage:before {
  content: "\eb4b";
}

.xpo-icon.xpo-icon-ss_strand:before {
  content: "\eb4c";
}

.xpo-icon.xpo-icon-ss_supplemental:before {
  content: "\eb4d";
}

.xpo-icon.xpo-icon-ss_tank_endorsement_expired:before {
  content: "\eb4e";
}

.xpo-icon.xpo-icon-ss_tank_endorsement_expiring_soon:before {
  content: "\eb4f";
}

.xpo-icon.xpo-icon-ss_tank_endorsement_valid:before {
  content: "\eb50";
}

.xpo-icon.xpo-icon-ss_time_date_critical:before {
  content: "\eb51";
}

.xpo-icon.xpo-icon-ss_trap_service:before {
  content: "\eb52";
}

.xpo-icon.xpo-icon-ss_unmapped:before {
  content: "\eb53";
}

.xpo-icon.xpo-icon-ss_volume_pickup:before {
  content: "\eb54";
}

/*
** ==== Basic colors ====
*/
/*
** ==== XPO Brand Color Palette ====
*/
/*
** ==== Main Color Palette ====
*/
/*
** ==== DEPRECATED COLORS ====
*/
/*
** WARNING: THIS VARIABLES WILL BE REMOVED SOON
*/
/*
** ==== COLORS APPLIED ====
*/
/*
** ==== Typography ====
*/
/*
** ==== SNACKBAR DEPRECATED VARIABLES ====
*/
/*
** WARNING: THIS VARIABLES WILL BE REMOVED SOON
*/
.flex--stretch {
  flex: 1;
}

.ag-column-hover {
  background-color: unset !important;
  color: unset !important;
}

.ag-cell.ag-cell-not-inline-editing.ag-cell-auto-height.ag-cell-value {
  height: 30px;
}

.xpo-AgGrid, .mat-checkbox,
.mat-button, .mat-raised-button,
.mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab,
.mat-mini-fab,
mat-form-field.mat-form-field-type-mat-input .mat-form-field-flex .mat-form-field-infix .mat-input-element,
mat-form-field.mat-form-field-type-mat-input .mat-form-field-label {
  font-family: "Inter", Arial, sans-serif;
}

xpo-advanced-select-panel xpo-advanced-select-tree-node,
xpo-advanced-select-panel mat-checkbox {
  margin-left: 0px !important;
}
xpo-advanced-select-panel xpo-advanced-select-tree-node .mat-checkbox-inner-container,
xpo-advanced-select-panel mat-checkbox .mat-checkbox-inner-container {
  margin-top: 7px !important;
}